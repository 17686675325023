import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss']
})
export class RequestStatusComponent {

  @Input() statusHeader:any;
  @Input() statusDescription:any;

}
