import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-place-holder',
  templateUrl: './place-holder.component.html',
  styleUrls: ['./place-holder.component.scss']
})
export class PlaceHolderComponent {
  @Input() img: string = ''
  @Input() imgSize: string = ''
  @Input() title: string = ''
  @Input() paragraph: string = ''

}
