<div class="row gx-lg-5 h-100">
    <!-- form section -->
    <div class="col-lg-6 ps-lg-3 h-100">
      <div class="d-flex flex-column justify-content-center bg-white border-f-radius-s20 py-4 px-lg-5 px-3 position-relative~ h-100">
        <!-- <div class="d-flex align-items-center gap-3 cursor-click mb-4" routerLink="/register/account-info"> -->
        <!-- arrow left -->
        <!-- <div
              class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
              <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
            </div> -->
        <!-- back text -->
        <!-- <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
          </div> -->
        <!-- form header -->
        <div class="d-flex flex-column align-items-center gap-3 pt-2 mb-3">
          <p class="m-0 text-dark-color font-Bold-s20">يرجى تحديد موقعك علي الخريطة</p>
          <p class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-3">
            لمساعدتنا في تخصيص تجربتك وربطك بالفرص ذات الصلة في منطقتك، نطلب منك تثبيت موقعك على الخريطة أدناه
          </p>
        </div>
        <!-- form -->
        <div class="position-relative">
          <!-- search -->
          <div
            class="search-holder bg-white border-f-radius-s12 py-3 px-3 d-flex align-items-center gap-2 position-absolute box-shadow">
            <i class="ic-all-request-Vector iC-S20 z-10 d-flex cursor-click img-Background-Contain"></i>
            <input placeholder="ابحث عن مدينة ، منطقة ، حي ، شارع....." autocorrect="off" autocapitalize="off" aria-label='search'
              spellcheck="off" type="text" class="form-control" #search id="searchInput" [formControl]="searchControl" />
          </div>

          <agm-map style="height:446px;" [zoom]='map_zoom' [latitude]="lat" [longitude]="lng" #AgmMap
            (mapClick)="mapClicked($event)">
            <agm-marker [latitude]="lati" [longitude]="lngi"></agm-marker>

          </agm-map>
          <!-- submit button -->
          <button class="bg-main py-2 w-100 font-Bold-s16 text-white border-f-radius-s12 mt-4" (click)="submit()"
          id="submit" aria-label="submit">
              <span *ngIf="this._AuthService.displayProggressBar">
                <mat-spinner></mat-spinner>
              </span>
              <span *ngIf="!this._AuthService.displayProggressBar" class="px-md-0 px-3">
                تأكيد الموقع
              </span>
          </button>
        </div>
      </div>

    </div>
  <!-- image section -->
  <div class="col-6 h-100" *ngIf="_BreakPointService.isMobile == false">
    <div class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
      <!-- image -->
      <i class="img-account-info image-holder img-Background-Contain d-flex"></i>
      <!-- section header -->
      <p class="m-0 text-dark-color font-Bold-s32 text-center">
        اهلا بك داخل منصة
        <span class="text-main-color">شغل</span>
      </p>
      <p class="m-0 font-Regular-s14 text-light-black-color text-center mt-2 w-75">
        تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء الموهوبين. يسعدنا وجودك على متن الطائرة،
        ونتطلع
        إلى دعمك في رحلتك المستقلة.
      </p>
    </div>
  </div>

</div>