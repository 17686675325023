import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-loader-progress',
  templateUrl: './loader-progress.component.html',
  styleUrls: ['./loader-progress.component.scss']
})
export class LoaderProgressComponent {
  options: AnimationOptions = {
    path: '../../../../../../assets/jsons/loading.json',
  };
  constructor(private _LoadingService: LoadingService) { }
  isLoading: Subject<boolean> = this._LoadingService.isLoading;
  animationCreated(animationItem: AnimationItem): void { }
}
