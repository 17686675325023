import { Component, ElementRef, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { MapsService } from 'src/app/core/services/maps.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { UsersService } from 'src/app/core/services/users.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

declare var $: any;

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent {
  @ViewChild("search", { static: false }) searchElementRef: ElementRef;
  autocomplete: google.maps.places.Autocomplete;
  map_zoom: number = 4;
  lati: number = 0;
  lngi: number = 0;
  lat: number = 0;
  lng: number = 0;
  address: any = ''
  addressName: any = ''
  roleIdErrorMessage:any;
  emailErrorMessage:any;
  fullnameErrorMessage:any;
  mobileErrorMessage:any;
  passwordErrorMessage:any;
  userNameErrorMessage:any;
  public searchControl: FormControl;

  constructor(public _toasterService: ToasterService ,private router: Router, private _chat:ChatService ,private _maps: MapsService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public _AuthService: AuthService, public usersService: UsersService, private _data: DataSaveService
    , public _BreakPointService: BreakPointService) {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };

  }
  ngOnInit(): void {
    this.searchControl = new FormControl();
    this.searchControl.setValue("");
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"]
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          //set latitude, longitude and zoom
          this.lati = place.geometry.location.lat();
          this.lngi = place.geometry.location.lng();
          this.lng = place.geometry.location.lng();
          this.lat = place.geometry.location.lat();
          this.map_zoom = 19;
        });
      });
    });
  }
  ngAfterViewInit(): void {
    this.getCurrentLocation()
  }
  getCurrentLocation(){
    if (navigator && navigator.geolocation) {
      const position = (pos: any) => {
        this.lng = pos.coords.longitude;
        this.lat = pos.coords.latitude;
        this.lngi = pos.coords.longitude;
        this.lati = pos.coords.latitude;
        this.map_zoom = 19
        this._maps.getRouteName(pos.coords.latitude, pos.coords.longitude).then(routeName => {
          this.addressName = routeName.formatted_address;
        });
      };
      const error = (error: any) => {
        // alert(JSON.stringify(error));
      };
      navigator.geolocation.getCurrentPosition(position, error);
      navigator.geolocation.watchPosition(position, error);
    }
  }
  mapClicked(event: MouseEvent) {
    this.lati = event.coords.lat
    this.lngi = event.coords.lng
    localStorage.setItem("coordinate",JSON.stringify({lat:event.coords.lat,lon:event.coords.lng}));
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      lat:this.lati,
      lng:this.lngi
    }
    setTimeout(() => {
      this._maps.getRouteName(event.coords.lat, event.coords.lng).then(routeName => {
        this.addressName = routeName.formatted_address;
        console.log(this.addressName)
      })
    }, 500);
  }
  submit(){
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      lat: this.lati,
      lan: this.lngi,
      addressName:this.addressName ,
      device_token: this._chat.token
    }
    this._AuthService.displayProggressBar = true;
    if (!this._AuthService.freelancerType) {
      this._AuthService.register(this._AuthService.registerationForm).subscribe((res: any) => {
          const { email, password, username } = this._AuthService.registerationForm;
          this._AuthService
          .signUp(email, password)
          .pipe(
            switchMap(({ user: { uid } }) =>
            this.usersService.addUser({ uid, email, displayName: username })
            )
            ).subscribe(() => {
              setTimeout(() => {
                this._AuthService.displayProggressBar = false;
                this.router.navigate(['/register/welcome']);
              }, 1490);
        })
        
      },err =>{
        this.roleIdErrorMessage = err.error.message.role_id
        this.emailErrorMessage = err.error.message.email
        this.fullnameErrorMessage = err.error.message.fullname
        this.mobileErrorMessage = err.error.message.mobile
        this.passwordErrorMessage = err.error.message.password
        this.userNameErrorMessage = err.error.message.username
        this._toasterService.showError(
          this.roleIdErrorMessage?this.roleIdErrorMessage:'' + this.emailErrorMessage? this.emailErrorMessage :'' +
          this.fullnameErrorMessage?this.fullnameErrorMessage:'' + this.mobileErrorMessage?this.mobileErrorMessage:'' + this.passwordErrorMessage?this.passwordErrorMessage:'' +
          this.userNameErrorMessage
        );
        this._AuthService.displayProggressBar = false;
      })
    } else {
      if (this._AuthService.freelancerType.roleId == '3') {
        this.router.navigate(['/register/freelancer-info']);
        this._AuthService.displayProggressBar = false;
      } else {
        this.router.navigate(['/register/company-info']);
        this._AuthService.displayProggressBar = false;
      }
    }
  }
}
