import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackBarComponent } from '../components/success-snack-bar/success-snack-bar.component';
import { ErrorSnackBarComponent } from '../components/error-snack-bar/error-snack-bar.component';
import { WarningSnackBarComponent } from '../components/warning-snack-bar/warning-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private snackBar: MatSnackBar) {}

  showSuccess(message: string, duration: number = 3000): void {
    this.snackBar.openFromComponent(SuccessSnackBarComponent, {
      data: { message },
      duration: duration,
    });
  }

  showError(message: string, duration: number = 3000): void {
    this.snackBar.openFromComponent(ErrorSnackBarComponent, {
      data: { message },
      duration: duration,
    });
  }

  showWarning(message: string, duration: number = 3000): void {
    this.snackBar.openFromComponent(WarningSnackBarComponent, {
      data: { message },
      duration: duration,
    });
  }
}
