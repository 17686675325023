import { Component, ElementRef , Input, NgZone, ViewChild } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { UsersService } from 'src/app/core/services/users.service';
import { Output, EventEmitter } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent {
  @ViewChild("search", { static: false }) searchElementRef: ElementRef;
  autocomplete: google.maps.places.Autocomplete;
  map_zoom: number = 4;
  @Output() latLang = new EventEmitter<string>();
  @Output() address = new EventEmitter<string>();
  lati:number = 0
  lngi:number = 0
  @Input() lat: number = 0;
  @Input() lng: number = 0;
  public searchControl: FormControl;
  constructor(
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, public _AuthService: AuthService, public usersService: UsersService) {}
  ngOnInit(): void {
    this.searchControl = new FormControl();
    this.searchControl.setValue("");
    this.mapsAPILoader.load().then(() => {
      let autocomplete:any = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"]
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(():any => {
          //get the place result
          this.address.emit(autocomplete?.gm_accessors_?.place?.Fi?.formattedPrediction)          
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return 'هذا العنوان غير معرف';
          }
          //set latitude, longitude and zoom
          this.lati = place.geometry.location.lat();
          this.lngi = place.geometry.location.lng();
          this.lng = place.geometry.location.lng();
          this.lat = place.geometry.location.lat();
          this.map_zoom = 19;
        });
      });
    });
    
  }
  ngAfterViewInit(): void {
    this.getCurrentLocation()
  }
  getCurrentLocation(){
    if (navigator && navigator.geolocation) {
      const position = (pos: any) => {
        this.lng = pos.coords.longitude;
        this.lat = pos.coords.latitude;
        this.lngi = pos.coords.longitude;
        this.lati = pos.coords.latitude;
        this.map_zoom = 19
      };
      const error = (error: any) => {
        // alert(JSON.stringify(error));
      };
      navigator.geolocation.getCurrentPosition(position, error);
      navigator.geolocation.watchPosition(position, error);
    }

  }
  mapClicked(event: MouseEvent) {
    this.lati = event.coords.lat
    this.lngi = event.coords.lng
    let value:any = { lati: this.lati, lngi: this.lngi }
    this.latLang.emit(value)
  }
  send() {
    let value:any = { lati: this.lati, lngi: this.lngi }
    this.latLang.emit(value)
    $('#myModal').click()
  }
}

