import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';

@Component({
  selector: 'app-freelancer-type',
  templateUrl: './freelancer-type.component.html',
  styleUrls: ['./freelancer-type.component.scss']
})
export class FreelancerTypeComponent {
  constructor(private _data: DataSaveService, private router: Router, public _AuthService: AuthService, private meta: Meta) {
    // meta description
    this.meta.updateTag({ name: 'description', content: 'نتشرف بإنضمامك كمشتغل سواء كنت عامل حر أو مستقل أو مقدم خدمه متفرغ.' });
  }
  data = {
    role_id: ''
  }
  freelancerTypesArray: any = [
    { name: 'حساب فرد', description: 'إذا كنت فردا تتطلع إلى تقديم مهاراتك وخدماتك للعملاء على منصتنا، فاختر هذا الخيار. ستتاح لك الفرصة لعرض', route: '/register/account-info', id: '3', icon: 'ic-freelancer-account' },
    { name: 'حساب شركة', description: 'اذا كنت تمثل شركة او موسسة وتبحث عن مواهب مستقلة من الدرجة الاولى ، او اردت تقديم خدماتك والوصول لعملاء جدد على منصتنا', route: '/register/account-info', id: '4', icon: 'ic-office-building' },
  ]

  saveData(id: any) {
    this._AuthService.freelancerType = {
      roleId: id
    }
    this._AuthService.registerationForm = {
      role_id: id
    }
    setTimeout(() => {
      this.router.navigate(['/register/account-info'])
    }, 1000)
  }
}
