import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {
  @Input() message:any;

  @Output()
  onDismissClick = new EventEmitter<any>();

  dismissClicked() {
    this.onDismissClick.emit(null);
  }
}
