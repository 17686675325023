import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigurationOptions, OutputOptionsEnum } from 'intl-input-phone';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from 'src/app/core/services/auth.service';

declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  usersArray = [
    { image: 'ic-user-6', index: 'z-index-6' },
    { image: 'ic-user-5', index: 'z-index-5' },
    { image: 'ic-user-4', index: 'z-index-4' },
    { image: 'ic-user-3', index: 'z-index-3' },
    { image: 'ic-user-2', index: 'z-index-2' },
    { image: 'ic-user-1', index: 'z-index-1' },
  ]
  advantagesArray = [
    { icon: 'ic-earth-americas', header: 'مجموعة واسعة من المشاريع', desc: 'اكتشف مجموعة متنوعة من المشاريع عبر مختلف المجالات والمهارات. من تطوير الويب إلى التصميم الجرافيكي، والكتابة إلى التدريس الخصوصي والفنين والمقاولين - ولدينا تصنيف اي شغل.' },
    { icon: 'ic-user-check', header: 'العملاء والمستقلون الذين تم التحقق منهم', desc: 'ثقتك وسلامتك أمر بالغ الأهمية بالنسبة لنا. يتم التحقق بدقة من جميع العملاء والمستقلين على منصتنا لضمان بيئة عمل آمنة وموثوقة.' },
    { icon: 'ic-plane-paper', header: 'التواصل في الوقت الفعلي', desc: 'ابق على اتصال مع العملاء أو المستقلين من خلال نظام المراسلة السلس الخاص بنا. ناقش تفاصيل المشروع، وقدم التحديثات، وتعاون بكفاءة - كل ذلك داخل المنصة.' },
    { icon: 'ic-star-comment-alt', header: 'مراجعات وتقييمات شفافة', desc: 'ردود الفعل الصادقة مهمة. يسمح نظام المراجعة والتصنيف الشفاف الخاص بنا للعملاء بتقييم أداء المستقلين والمستقلين لتقييم الاحتراف المهني للعملاء.' },
    { icon: 'ic-shield-check', header: 'مدفوعات آمنة ومأمونة', desc: 'المدفوعات الخالية من القلق هي أولويتنا. نستخدم بوابة دفع آمنة لمعالجة جميع المعاملات، مما يضمن تعويضا دقيقا في الوقت المناسب عن عملك الشاق.' },
    { icon: 'ic-time-twenty-four', header: 'دعم العملاء على مدار الساعة طوال أيام الأسبوع', desc: 'هل لديك سؤال أو تحتاج إلى مساعدة؟ يتوفر فريق دعم العملاء المتفاني لدينا على مدار الساعة لتقديم مساعدة سريعة وودية.' },
  ]
  contactArray = [
    { icon: 'ic-envelope-home', contactName: 'البريد الإلكتروني', contactDetails: 'Admin@shogol.sa' },
    { icon: 'ic-phone-home', contactName: 'رقم الجوال للتواصل', contactDetails: '(+966) 568-4568-124' },
    { icon: 'ic-calendar-clock-home', contactName: 'ساعات العمل', contactDetails: 'ممتنين لخدمتك طوال آيام الأسبوع' },
  ]
  freelancerWork = [
    { name: 'اشغال المنزل', number: '4635 طلب', icon: 'ic-home-login ' },
    { name: 'اشغال السيارات', number: '1346 طلب', icon: 'ic-car-login' },
    { name: 'اشغال الصيانه', number: '767 طلب', icon: 'ic-setting-login' },
    { name: 'التعاملات الالكترونيه', number: '6543 طلب', icon: 'ic-connection-login' },
    { name: 'اشغال الضيافة', number: '987 طلب', icon: 'ic-hand-login' },
    { name: 'اشغال الاسر المنتجة', number: '65 طلب', icon: 'ic-people-login' },
  ]
  anyJop = { name: 'اي شغل' }
  NumberModel: string = "+966";
  configOption1: ConfigurationOptions;
  remainingText: any
  count = 4100;
  duration = 10000;
  requestsduration = 10000
  requestsCount = 12440
  freelancersCount = 1200
  freelancersduration = 10000
  fieldsCount = 3940
  fieldsduration = 10000
  phoneNumForm: FormGroup;
  message: string = ''
  @ViewChild('conatctForm', { static: false }) conatctForm!: NgForm;
  errMessage: string = ''
  constructor(private meta: Meta , private formBuilder: FormBuilder, public _AuthService: AuthService, private router: Router) {
    // meta description
    this.meta.updateTag({ name: 'description', content: 'شغل منصة رسمية لتنظيم قطاع الخدمات وسوق العمل الحر تعمل بمفهوم جديد وطرق مبتكره تثمن وقتك كمستقل وكمستخدم' });
  }
  options: AnimationOptions = {
    path: '../../../../../../assets/jsons/contact.json',
  };

  ngOnInit(): void {
    let input:any = document.getElementsByName('InputPhone')[0];
    input.placeholder = '0xxxxxxxxxx'
    this.phoneNumForm = this.formBuilder.group({
      sampleReactiveControl: new FormControl('', [Validators.required])
    });
    this.configOption1 = new ConfigurationOptions();
    this.configOption1.OutputFormat = OutputOptionsEnum.Number
  }

  myChange(val: any) {
    this.remainingText = val?.length + '/150';
  }

  ngSubmit(formValue: any) {
    let mobileNumber = formValue.mobile.CountryModel.CountryPhoneCode + formValue.mobile.Number
    let numberNoSpace = mobileNumber.replace(/\s/g, "");
    let numberNoPlus = numberNoSpace.substring(1)
    let data = {
      mobile: numberNoPlus,
      email: formValue.email,
      description: formValue.description,
      name: formValue.fullname
    }
    this._AuthService.contactUsHome(data).subscribe((res: any) => {
        this.message = 'تم التواصل بنجاح'
        this.errMessage = ''
        $("#message").show().delay(5000).fadeOut();
        this.conatctForm.resetForm();
        this.NumberModel = '+966'
        formValue.mobile.Number = ''
        this.remainingText = ''
    })
  }

  freelancer() {
    localStorage.getItem('user')! ? this.router.navigate(['/request']): this.router.navigate(['/register/freelancer-type']);
  }
}
