import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent {
  otp: string = '';
  showOtpComponent = true;
  dError: string = 'd-none'
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
  };
  constructor(private _AuthService: AuthService, private _data: DataSaveService, private router: Router, public snackBar: MatSnackBar
    , public _toasterService: ToasterService, public _BreakPointService:BreakPointService) {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }

  toggleDisable() {
    if (this.ngOtpInput.otpForm) {
      if (this.ngOtpInput.otpForm.disabled) {
        this.ngOtpInput.otpForm.enable();
      } else {
        this.ngOtpInput.otpForm.disable();
      }
    }
  }
  onOtpChange(otp: any) {
    this.otp = otp;
    if (otp.length === 4) {
      this.toggleDisable()
      this._AuthService.registerationForm = {
        ...this._AuthService.registerationForm,
        code: otp
      }

      this._AuthService.checkOtpCode(this._AuthService.registerationForm).subscribe((res: any) => {
          this._toasterService.showSuccess('تم ارسال الكود');
          setTimeout(() => {
              this.router.navigate(['/register/location-info']);
        }, 1490);
          this.dError = 'd-none'
      }, err => {
        this._toasterService.showError('الرمز الذى أدخلته غير صحيح');
        this.toggleDisable()
      })
    }
    let code = this._AuthService.registerationForm
    this._data.setNewUserInfo(code)
  }

}
