import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationOptions, OutputOptionsEnum } from 'intl-input-phone';
declare var $: any;
@Component({
  selector: 'app-mobile-input',
  templateUrl: './mobile-input.component.html',
  styleUrls: ['./mobile-input.component.scss']
})
export class MobileInputComponent {
  phoneNumForm: FormGroup;
  @Input() NumberModel: string = '+966';
  @Input() disabled: boolean = false;
  @Output() mobileNumber = new EventEmitter<string>();
  configOption1: ConfigurationOptions;
  constructor(public _auth: AuthService, private formBuilder: FormBuilder, private profile: ProfileService) {
    setTimeout(() => {
      let input:any = document.getElementsByName('InputPhone')[0]
      input.placeholder = '0xxxxxxxxxx'
    }, 100);
    this.phoneNumForm = this.formBuilder.group({
      sampleReactiveControl: new FormControl('', [Validators.required])
    });
    this.configOption1 = new ConfigurationOptions();
    this.configOption1.OutputFormat = OutputOptionsEnum.Number 
      // this.NumberModel = `+${String(res?.data?.mobile).slice(2, 4)} ${String(res?.data?.mobile).slice(3) }`
  }
  change(formValue:any){
    let mobileNumber = formValue.CountryModel.CountryPhoneCode + formValue.Number
    let numberNoSpace = mobileNumber.replace(/\s/g, "");
    let numberNoPlus = numberNoSpace.substring(1)
    this.mobileNumber.emit(numberNoPlus)
  }
}
