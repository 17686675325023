<span class="example-pizza-party notification border-f-radius-s15 px-2 pt-2 d-block">
    <div class="d-flex align-items-center gap-8px pb-2"  style="direction: rtl;">
        <div class="vertical-line line-color border-f-radius-s5"></div>
        <div class="notification border-f-radius-s10 p-2">
            <i class="ic-nav-notification iC-S24 img-Background-Contain d-flex" (click)="dismissClicked()"></i>
        </div>
        <div class="d-flex flex-column justify-content-between">
            <p class="m-0 text-style font-Bold-s18">
                {{data?.title}}
            </p>
            <p class="m-0 text-style font-Regular-s16">
            {{data?.body}}
            </p>
        </div>
    </div>
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
</span>
