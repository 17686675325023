import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-forget-password-otp',
  templateUrl: './forget-password-otp.component.html',
  styleUrls: ['./forget-password-otp.component.scss']
})
export class ForgetPasswordOtpComponent {
  otp: string = '';
  showOtpComponent = true;
  dError: string = 'd-none'
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '-',
  };
  constructor(private _AuthService: AuthService, private _data: DataSaveService, private router: Router, public snackBar: MatSnackBar
    , public _toasterService: ToasterService) {
  }
  toggleDisable() {
    if (this.ngOtpInput.otpForm) {
      if (this.ngOtpInput.otpForm.disabled) {
        this.ngOtpInput.otpForm.enable();
      } else {
        this.ngOtpInput.otpForm.disable();
      }
    }
  }
  onOtpChange(otp: any) {
    this.otp = otp;
    if (otp.length === 4) {
      this.toggleDisable()
      this._AuthService.loginForm = {
        ...this._AuthService.loginForm,
        code: otp
      }
      this._AuthService.checkForgetOtpCode(this._AuthService.loginForm).subscribe((res: any) => {
          this._AuthService.changePasswordId = res?.data?.result?.id
          this._toasterService.showSuccess('تم ارسال الكود');
          setTimeout(() => {
            this._AuthService.verificationChecked = 2
          }, 1490);
          this.dError = 'd-none'
      }, err => {
        this.dError = 'd-block'
        this._toasterService.showError('الرمز الذى أدخلته غير صحيح');
        this.toggleDisable()
      })
    }
    let code = this._AuthService.loginForm
    this._data.setNewUserInfo(code)
  }

}
