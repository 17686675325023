import { Injectable, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ProfileService } from './profile.service';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class TreeService {
  category = new BehaviorSubject(null);
  checked: boolean = false
  id: any;
  getLevel = (node: any) => node.level;
  public transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children?.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      parent_id: node.parent_id,
      checked: false
    };
  };
  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource: any = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  private searchTerms = new BehaviorSubject<string>('');

  constructor(public _categories: CategoriesService,
    public router: Router,
    public profile: ProfileService,
    public _auth: AuthService) {
    this._auth.getUserId
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.removeAll(this.checklistSelection.selected)
        this.category.next([])
        this.whatSteelyOfTree()
      }
    })

    this.searchTerms.pipe(
      debounceTime(300),  // Wait for 300ms before making the API call
      distinctUntilChanged(),  // Only proceed if the search term has changed
      switchMap(term => this._categories.searchCategories(term))
    ).subscribe((res: any) => {
      this.dataSource.data = res.data;
    });
    
  }
  whatSteelyOfTree() {
    if (this.router.url.includes("/ads/add-new-advrties") || this.router.url.includes("/ads/edit-ads")) {
      this.profile.getUserData(this._auth.id).subscribe((res: any) => {
        this.dataSource.data = res?.data?.category
        if (this.router.url.includes("/ads/add-new-advrties")) {
          this.dataSource._treeControl.dataNodes?.map((el: any) => {
            res.data.data?.category?.map((ele) => {
              if (el.name == ele.name) {
                // this.todoLeafItemSelectionToggle(el);
              }
            })
          })
        }
      })
    } else if (this.router.url.includes(`/profile/${this.id}/Occupy-me`)) {
      this.profile.getUserData(this.id).subscribe((res: any) => {
        this.dataSource.data = res?.data?.category
        this.dataSource._treeControl.dataNodes?.map((el: any) => {
          res.data.data?.category?.map((ele) => {
            if (el.name == ele.name) {
              this.todoLeafItemSelectionToggle(el)
            }
          })
        })
      })
    } else {
      this._categories.getCategories().subscribe((res: any) => {
        console.log(res)
        this.dataSource.data = res.data;
        console.log(this.dataSource)
        console.log(this.treeControl)
  
        
      })
    }
  }
  /** change element to node */
  changeElementToNode(node: any): void {
    this.dataSource._treeControl.dataNodes?.map((el: any) => {
      node?.map((ele: any) => {
        if (el.name == ele.name) {
          this.todoLeafItemSelectionToggle(el)
        }
      })
    })
  }

  hasChild = (_: any, node: any) => node.expandable;
  checklistSelection = new SelectionModel<any>(true);
  descendantsAllSelected(node: any): boolean {
   // console.log(node)
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }
  descendantsPartiallySelected(node: any): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }
  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node)
    this.category.next(this.checklistSelection.selected.map(el => el))
  }
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.category.next(this.checklistSelection.selected.map(el => el))
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: any): void {
    let parent: any | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }
  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: any): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes?.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  removeAll(node: any) {
    this.checklistSelection.deselect(...node)
    this.checklistSelection.selected.map((a: any) => a.checked = false);
  }
  // search for categories
  searchForCategories(event: any) {
    const searchTerm = event.target.value;
    this.searchTerms.next(searchTerm);
  }
}
