import { Injectable} from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class DataSaveService {
  constructor() { }
  dataSave:any = {}
  homeDiv:any
  setNewUserInfo(user: any) {
    this.dataSave = Object.assign(this.dataSave , user);
  }
  formData = new FormData();

  FormData(){
    this.formData.append('role_id', this.dataSave.role_id);
    this.formData.append('fullname', this.dataSave.fullname);
    this.formData.append('email', this.dataSave.email);
    this.formData.append('password', this.dataSave.password);
    this.formData.append('category', this.dataSave.category);
    this.formData.append('username', this.dataSave.username);
    this.formData.append('info', this.dataSave.info);
    this.formData.append('job_name', this.dataSave.job_name);
    this.formData.append('mobile', this.dataSave.mobile);
    if (this.dataSave.city_id !== undefined) {
      this.formData.append('city_id', this.dataSave.city_id);
    }
    if (this.dataSave.state_id !== undefined) {
      this.formData.append('state_id', this.dataSave.state_id);
    }
    if (this.dataSave.country_id !== undefined) {
      this.formData.append('country_id', this.dataSave.country_id);
    }
    if (this.dataSave.avatar !== undefined) {
    this.formData.append('avatar', this.dataSave.avatar);
    }
    if (this.dataSave.gender_id !== undefined) {
    this.formData.append('gender_id', this.dataSave.gender_id);
    }
    if (this.dataSave.nationality_id !== undefined) {
    this.formData.append('nationality_id', this.dataSave.nationality_id);
    }
    for (let i = 0; i < this.dataSave.document.length; i++) {
      this.formData.append('document', this.dataSave.document[i]);
    }
    if (this.dataSave.commercial_number !== undefined){
      this.formData.append('commercial_number', this.dataSave.commercial_number);
    }
    if (this.dataSave.nationality_number !== undefined) {
    this.formData.append('nationality_number', this.dataSave.nationality_number);
    }
    if (this.dataSave.area_name !== undefined) {
    this.formData.append('area_id', '0');
    this.formData.append('area_name', this.dataSave.area_name);
    }

}
}
