import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
@Input() btnText:string = ''
@Input() pX:any
@Input() pY:any
@Input() bgColor:any
@Input() textColor:any
@Input() font:any
@Input() routerLink:string =  ''
@Input() id:string =  ''
}
