import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { RequestService } from 'src/app/core/services/request.service';

@Component({
  selector: 'app-request-filteration',
  templateUrl: './request-filteration.component.html',
  styleUrls: ['./request-filteration.component.scss']
})
export class RequestFilterationComponent {
  isOpen: boolean = false
  chevron: string = 'chevron_left'
  location: string = '';
  disabled = false;
  max = 11350;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  currentPage: any = 1;
  total: any;
  maxValue = 11350;
  minValue = 0;
  data: any
  totalPage: number;
  category: any[] = []
  seeMore: string = '...'
  starRating = 0;
  constructor(public _Request: RequestService, private _http: HttpClient){}
}
