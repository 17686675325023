<div class="row gx-lg-5 h-100">
  <!-- form section -->
  <div class="col-lg-6 ps-lg-3">
    <div class="bg-white border-f-radius-s20 py-lg-4 py-2 px-lg-5 px-3  " >
      <div class="d-flex align-items-center gap-3 cursor-click mb-1" routerLink="/register/location-info">
        <!-- arrow left -->
        <div
          class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
          <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
        </div>
        <!-- back text -->
        <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
      </div>
      <!-- form header -->
      <div class="d-flex flex-column align-items-center gap-3">
        <p class="m-0 text-dark-color font-Bold-s20">معلومات حساب المشتغل</p>
        <p class="m-0 text-light-black-color font-Regular-s14 text-center  px-2">
          مرحبا بك في منصة شغل، حيث تعد هويتك الفريدة وخبرتك المهنية ضرورية للتواصل مع العملاء وعرض مهاراتك. للبدء،
          ستحتاج إلى
          تقديم بعض المعلومات الرئيسية، بما في ذلك رقم هويتك والمسمى الوظيفي.
        </p>
      </div>
      <!-- form -->
      <form class="mt-4" #freelancerInfo='ngForm' autocomplete="off" (ngSubmit)="onSubmit(freelancerInfo.value)">
        <!-- user image -->
        <div class="d-flex align-items-center justify-content-between gap-3">
          <input type='file' id="imageUpload" name="avatar" ngModel accept=".png, .jpg, .jpeg" #fileInput
            (change)="uploadFile($event)" aria-label="imageUpload"  required/>
            <!-- [required]="imageUpload" -->
          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
            class="flex-shrink-0  img-Background-Cover p-4 bg-white-sup Border-dashed border-f-radius-s12 d-flex justify-content-center align-items-center"
            [ngClass]="{'imageUpload': imageUpload , ' ' : !imageUpload}">
            <i class="cursor-click ic-camera iC-S20 img-Background-Contain" (click)="fileInput.click()"></i>
          </div>
          <div class="d-flex flex-column gap-2 h-100 align-items-between justify-content-between">
            <p class="m-0 font-Bold-s16 text-dark-color">الصورة الشخصية</p>
            <p class="m-0 text-light-black-color font-Regular-s14 line-height-15">
              اضف صورتك الشخصية لتحصل على موثوقيه اعلى وتكون لديك فرص ربح اكبر داخل المنصة </p>
          </div>
        </div>
        <!-- job name -->
        <div class="d-flex flex-column gap-2 w-100 mt-3">
          <p class="m-0 font-Bold-s14 text-dark-color">
            المسمى الوظيفى
          </p>
          <input type="text" name="job_name" autocomplete="none" aria-label="job_name" required
            class="form-control Border-light-grey  bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
            id="job_name" aria-describedby="nameHelp" ngModel #job_name="ngModel" placeholder="مدرس رياضيات .. مصمم متاجر"
            minlength="8" maxlength="50">
          <div *ngIf="job_name.errors && (job_name.dirty || job_name.touched)">
            <p *ngIf="job_name.errors?.['minlength']" class="m-0 CC-alert"> المسمى الوظيفى يجب أن لا يقل عن 8 حروف
              والارقام يجب ادخالها بالانجليزية
            </p>
            <p *ngIf="job_name.errors?.['maxlength']" class="m-0 CC-alert">
              المسمى الوظيفى يجب أن لا يقل عن 50 حروف
            </p>
          </div>
        </div>
        <!-- about -->
        <div class="d-flex flex-column gap-2 w-100 mt-3">
          <p class="m-0 font-Bold-s14 text-dark-color">
            نبذة عني
          </p>
          <div class="w-100 relative">
            <textarea
              placeholder="يجب وصف النفس بدقة شديدة وبثقة عالية، ابدأ بمقدمة قوية وجاذبة اذكر المعلومات الأساسية عن نفسك ( اسمك عمرك شهاداتك خبراتك انجازاتك وذكر عدد سنوات الخبرة العملية والجهات التي عملت معها مع التنويه إلى خبراتك في العمل بشكل مستقل في حال وجودها)"
              class="form-control Border-light-grey bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
              name="info" cols="30" rows="8" ngModel required #info="ngModel" id="info" minlength="30" maxlength="5000"
              (ngModelChange)="myChange($event)"></textarea>
            <span
              class="position-absolute bottom-4 text-support2-color font-Regular-s16 left-4">{{remainingText}}</span>
          </div>
          <div *ngIf="info.errors && (info.dirty || info.touched)">
            <p *ngIf="info.errors?.['minlength']" class="m-0 CC-alert"> نبذة عني يجب أن لا يقل عن 30 حرف والارقام يجب
              ادخالها بالانجليزية
            </p>
            <p *ngIf="info.errors?.['maxlength']" class="m-0 CC-alert">
              نبذة عني يجب أن لا تزيد عن 5000 حرف
            </p>
          </div>
          <!-- <input class="d-none" [(ngModel)]="description" name="description" minlength="30" maxlength="5000" required type="text">
          <app-textarea-summernote (send)="textarea($event)" (required)="textarearequired($event)"></app-textarea-summernote> -->
        </div>
        <!-- nationaltiy number  -->
        <!-- <div class="d-flex flex-column gap-2 w-100 mt-3 mb-2">
          <p class="m-0 font-Bold-s14 text-dark-color">
            الرقم الوطني
          </p>
          <input type="text" name="nationality_number" autocomplete="none" aria-label="nationality_number"
            class="form-control Border-light-grey  bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
            id="nationality_number" aria-describedby="nameHelp" ngModel #nationality_number="ngModel"
            placeholder="١٢٣٤٥٦٧٨٩" minlength="4" maxlength="30" required>
          <div *ngIf="nationality_number.errors && (nationality_number.dirty || nationality_number.touched)">
            <p *ngIf="nationality_number.errors?.['minlength']" class="m-0 CC-alert"> الرقم الوطني يجب أن لا يقل عن 4
              حروف والارقام يجب ادخالها بالانجليزية
            </p>
            <p *ngIf="nationality_number.errors?.['maxlength']" class="m-0 CC-alert">
              الرقم الوطني يجب أن لا يقل عن 30 حروف والارقام يجب ادخالها بالانجليزية
            </p>
          </div>
        </div> -->
        <!-- next -->
        <button type="submit" [disabled]="freelancerInfo.invalid" id="submit" aria-label="submit"
          class="w-100 border-f-radius-s12 border-0 btn-holder py-3 px-5 bg-main text-white font-Bold-s16 mt-3">
          <span *ngIf="this._AuthService.displayProggressBar">
            <mat-spinner></mat-spinner>
          </span>
          <span *ngIf="!this._AuthService.displayProggressBar" class="px-lg-0 px-3">التالي</span>
        </button>
      </form>
    </div>
  </div>
  <!-- image section -->
  <div class="col-6 h-100"  *ngIf="_BreakPointService.isMobile == false">
    <div class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
      <!-- image -->
      <i class="img-account-info image-holder img-Background-Contain d-flex"></i>
      <!-- section header -->
      <p class="m-0 text-dark-color font-Bold-s32 text-center">
        اهلا بك داخل منصة
        <span class="text-main-color">شغل</span>
      </p>
      <p class="m-0 font-Regular-s14 text-light-black-color text-center mt-2 w-75">
        تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء الموهوبين. يسعدنا وجودك على متن الطائرة،
        ونتطلع
        إلى دعمك في رحلتك المستقلة.
      </p>
    </div>
  </div>

</div>
<!-- user feedback -->