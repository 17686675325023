import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ConfigurationOptions, NumberResult, OutputOptionsEnum } from 'intl-input-phone';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
declare var $: any;


@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss']
})
export class AccountInfoComponent {
  action: boolean = true;
  passwordIcon = 'ic-hidden';
  showPassword: boolean = false;
  configOption1: ConfigurationOptions;
  phoneNumForm: FormGroup;
  NumberModel: string = "+966";
  OutputValue2: NumberResult = new NumberResult();
  msg: any;
  mobileWarning: any
  number: any
  errorMessage: any;
  constructor(private formBuilder: FormBuilder, private _data: DataSaveService, public _AuthService: AuthService, private router: Router
    , public _toasterService: ToasterService, public _BreakPointService: BreakPointService, private meta: Meta) {
    // meta description
    this.meta.updateTag({ name: 'description', content: 'اهلا بك في أسرع منصة تثمن وقتك لإنجاز أشغالك' });

    setTimeout(() => {
      let input: any = document.getElementsByName('InputPhone')[0]
      input.placeholder = '0xxxxxxxxxx'
    }, 100);
    this.phoneNumForm = this.formBuilder.group({
      sampleReactiveControl: new FormControl('', [Validators.required, Validators.minLength(2)])
    });
    this.configOption1 = new ConfigurationOptions();
    this.configOption1.OutputFormat = OutputOptionsEnum.Number
  }
  writingEngilshOnly(event: any) {
    return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)
  }

  ngOnInit(): void {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }
  change(e: any) {

    if (e?.CountryPhoneCode == +966) {
      this.mobileWarning = true
    } else {
      this.mobileWarning = false
    }
  }
  onKeyPress(event: any) {
    const charCode = event.which || event.keyCode;
    if ((charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      event.preventDefault();
    }
  }
  // hide password function
  hidePassword() {
    this.showPassword = !this.showPassword
    switch (this.showPassword) {
      case false:
        this.passwordIcon = 'ic-hidden'
        break;
      case true:
        this.passwordIcon = 'ic-eyes'
        break;
    }
  }
  ToggbleEnableDisableState() {
    if (this.phoneNumForm.controls["sampleReactiveControl"].disabled) {
      this.phoneNumForm.controls["sampleReactiveControl"].enable();
    } else {
      this.phoneNumForm.controls["sampleReactiveControl"].disable();
    }
  }
  // phone number submit
  onSubmit(outputResult: any) {
    const firstDigitStr = String(outputResult.mobile.Number)[0];
    let num = outputResult.mobile.Number
    let numberNoSpace = num.replace(/\s/g, "");
    let result = numberNoSpace.toString().substring(1);
    let mobileNumber = parseInt(result);
    let codeAndNumber = outputResult.mobile.CountryModel.CountryPhoneCode + mobileNumber
    let codeAndNumberNoSpace = codeAndNumber.replace(/\s/g, "")
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      mobile: codeAndNumberNoSpace,
      fullname: outputResult.fullName,
      username: outputResult.userName,
      email: outputResult.email,
      password: outputResult.password
    }
    this._AuthService.phoneProccess = {
      mobile: codeAndNumberNoSpace
    }
    this._AuthService.displayProggressBar = true;
    this._AuthService.checkUserName(outputResult.userName).subscribe((res: any) => {
        this._AuthService.checkEmail(outputResult.email).subscribe((res: any) => {
            this._AuthService.checkMobileNumber(this._AuthService.phoneProccess).subscribe((res: any) => {
                if (firstDigitStr == '0') {
                  this._toasterService.showSuccess('تم ارسال البيانات بنجاح');
                  this._AuthService.displayProggressBar = false;
                  setTimeout(() => {
                    this.router.navigate(['/register/account-otp'])
                  }, 1490);
                } else {
                  this._toasterService.showError(this.errorMessage);
                  this._AuthService.displayProggressBar = false;
                }
            }, err => {
              this.errorMessage = err.error.message
              this._AuthService.displayProggressBar = false;
              this._toasterService.showError(this.errorMessage);
            })
          
        }, err => {
          this.errorMessage = err.error.message.email
          this._toasterService.showError(this.errorMessage);
          // (<HTMLInputElement>document.getElementById("informationFormBtn")).disabled = true
          this._AuthService.displayProggressBar = false;
        })
    }, err => {
      console.log(err)
      this.errorMessage = err.error.errors.error_message;
      this._AuthService.displayProggressBar = false;
      this._toasterService.showError(this.errorMessage);
    })
  }
}
