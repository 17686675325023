<button mat-icon-button [color]="color" *ngFor="let ratingId of ratingArr;index as i" [id]="'star_'+i"
id="star" aria-label="star"
    (click)="onClick(i+1)">
    <mat-icon>
        {{showIcon(i)}}
    </mat-icon>
</button>
<mat-error *ngIf="starCount == null || starCount == 0">
    Star count is <strong>required</strong> and cannot be zero
</mat-error>
