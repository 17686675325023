import { Component, Input ,OnChanges} from '@angular/core';
import Splide from '@splidejs/splide';
declare var $: any;

@Component({
  selector: 'app-splide',
  templateUrl: './splide.component.html',
  styleUrls: ['./splide.component.scss']
})
export class SplideComponent  implements OnChanges {
  @Input() documents:any 
  imagesPopup:any
  documentPdf:any[] = []
  documentImage:any[] = []
  documentVideo:any[] = []
  ngOnChanges() {
    for (let i = 0; i < this.documents?.length; i++) {
      let fileExtension = this.documents[i].file?.split(".")?.splice(-1);
    if (fileExtension == 'txt' || fileExtension == 'pdf' || fileExtension == 'pptx' || fileExtension == 'ppt' || fileExtension == 'xls' || fileExtension == 'xlsx' || fileExtension == 'doc' || fileExtension == 'docx' || fileExtension == 'word') {
      this.documentPdf.push(this.documents[i].file)
  } else {
    if (!this.documents[i]?.file?.includes('.mp4')) {
        this.documentImage.push(this.documents[i])
      }else {
        this.documentVideo.push(this.documents[i])
      } 
  }
  }
  setTimeout(() => {
        let splide = new Splide('.splide', {
          type: 'loop',
          padding: '7rem',
          breakpoints: {
            1024: {
              perPage: 3,
              padding: '1rem',
            },
            767: {
              perPage: 2,
              padding: '1rem',
            },
            640: {
              perPage: 1,
              padding: '1rem',
            },
          },
        });
        splide.mount();
}, 300);
}
openPopup(file:any){
  this.imagesPopup = file
  $('#imagePopup').modal('show');
}
}
