<div
  class="container-fluid px-0 d-flex flex-column bg-white-sup position-relative"
  style="height: 100vh;">
  <nav class="container px-0 pt-3 pb-4 pe-4">
    <!-- navbar logo -->
    <button class id="logo" aria-label="logo">
      <img class="logo-holder cursor-click"
        src="../../../../assets/Icons/main-logo.svg" alt="Your Company logo"
        routerLink="/" loading="lazy">
    </button>
  </nav>
  <div class="container bg-white-sup px-0 w-100 h-100">
    <div class="row m-0 gx-5 w-100 h-100">
      <!-- form section -->
      <div class="col-lg-6 ps-3 col-12">
        <div class="bg-white border-f-radius-s20 py-5 py-md-4 px-5 ">
          <!-- form header -->
          <div class="d-flex flex-column align-items-center gap-4 mt-md-5 mt-4 ">
            <!-- forgetPassword header -->
            <p class="m-0 text-dark-color font-Bold-s20">تغيير كلمة المرور</p>
            <!-- forgetPassword description -->
            <p
              class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-4">
              “أطلق إمكاناتك. احتضان الحرية. قم بتمكين رحلتك المستقلة"
            </p>
          </div>
          <!-- form -->
          <form class #forgetPasswordForm='ngForm'
            (ngSubmit)="ngSubmit(forgetPasswordForm.value)">
            <div class="mt-3 d-flex flex-column align-items-start position-relative w-100 pb-2">
              <p class="m-0 font-Bold-s14 text-dark-color mb-2">
                كلمة المرور
              </p>
              <input [type]="this.showPassword ? 'text' : 'password'"
                name="password" aria-label="password"
                class="font-Regular-s16 d-flex position-relative justify-content-between px-3 py-2.5 align-items-center w-100 bg-white border-f-radius-s15 Border-light-grey"
                autocomplete="one-time-code" id="password"
                aria-describedby="passwordHelp" ngModel #password="ngModel"
                required
                placeholder="**********"
                minlength="8" maxlength="15">
              <i
                class="cursor-click left-4 lg:top-11 top-9 position-absolute {{passwordIcon}} img-Background-Contain iC-S16 d-flex"
                (click)="this.hidePassword()" [class.hide]="this.showPassword"></i>
              <div
                *ngIf="password.errors && (password.dirty || password.touched)">
                <p *ngIf="password.errors?.['minlength']" class="m-0 CC-alert">
                  كلمة المرور يجب أن لا يقل عن 8 حروف والارقام يجب ادخالها
                  بالانجليزية</p>
                <p *ngIf="password.errors?.['maxlength']" class="m-0 CC-alert">
                  كلمة المرور يجب أن لا تزيد عن 25 حرف والارقام يجب ادخالها
                  بالانجليزية</p>
              </div>
            </div>
            <div class="mt-3 d-flex flex-column align-items-start w-100 pb-2">
              <p class="m-0 font-Bold-s14 text-dark-color mb-2">
                تأكيد كلمة المرور
              </p>
              <div
                class="d-flex justify-content-between px-3 py-2 align-items-center w-100 bg-white border-f-radius-s15 Border-light-grey">
                <input
                  [type]="this.showPasswordConfirmation ? 'text' : 'password'"
                  name="password_confirmation" aria-label="password"
                  class="w-100 py-1 bg-white font-Regular-s16 "
                  autocomplete="one-time-code" id="password_confirmation"
                  aria-describedby="passwordHelp" ngModel
                  #password_confirmation="ngModel" required
                  placeholder="**********" minlength="8"
                  maxlength="25">
                <i
                  class="cursor-click {{showPasswordConfirmationIcon}} img-Background-Contain iC-S16 d-flex"
                  (click)="hidePasswordConfirmation()"
                  [class.hide]="this.showPasswordConfirmation"></i>
              </div>
              <div
                *ngIf="password_confirmation.errors && (password_confirmation.dirty || password_confirmation.touched)">
                <p *ngIf="password_confirmation.errors?.['minlength']"
                  class="m-0 CC-alert"> كلمة المرور يجب أن لا يقل عن 8 حروف
                  والارقام يجب ادخالها بالانجليزية</p>
                <p *ngIf="password_confirmation.errors?.['maxlength']"
                  class="m-0 CC-alert"> كلمة المرور يجب أن لا تزيد عن 25 حرف
                  والارقام يجب ادخالها بالانجليزية</p>
              </div>
            </div>
            <button [disabled]="forgetPasswordForm.invalid" type="submit"
              id="submit" aria-label="submit"
              class="mt-4 w-100 text-white bg-main border-f-radius-s12 py-2 font-Bold-s16">تأكيد
              كلمة المرور </button>
          </form>
          <!-- forget password button -->
          <button routerLink="/forget-password" id="password"
            aria-label="password"
            class="my-4 w-100 bg-white border-f-radius-s12 py-2 font-Bold-s14 gap-1 d-flex justify-content-center align-items-center">
            <i class="ic-forget-password img-Background-Contain iC-S16 d-flex"></i>
            <p class="m-0 text-dark-color">نسيت كلمة المرور؟</p>
          </button>
          <!-- new account text -->
          <p class="m-0 text-support2-color font-Regular-s14 text-center">
            <span *ngIf="this._AuthService.displayProggressBar">
              <mat-spinner></mat-spinner>
            </span>
            <span *ngIf="!this._AuthService.displayProggressBar">
              لايوجد حساب لديك داخل منصة شغل
            </span>
          </p>
          <!-- register text-->
          <div class="w-100 d-flex justify-content-center mt-3 mb-5">
            <button routerLink="/register" id="newACC" aria-label="newACC"
              class="font-Bold-s14 px-5 py-2 bg-white btn-border text-main-color border-f-radius-s12">إنشاء
              حساب
              الآن</button>
          </div>
        </div>
      </div>
      <!-- image section -->
      <div class="col-lg-6 h-100 col-12 d-lg-block d-none">
        <div
          class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
          <!-- image -->
          <i
            class="ic-forget-password-graph image-holder img-Background-Contain d-flex"></i>
        </div>
      </div>
    </div>

  </div>
  <!-- footer -->
  <footer
    class="bg-dark mt-auto pt-5 pb-3 d-flex justify-content-center position-fixed bottom-0 start-0 end-0"
    style="z-index: 1;">
    <p class="m-0 font-Regular-s14 text-white-color">
      Copyright ©2023 All rights reserved | Shogol Platform by lun startup
      studio
    </p>
  </footer>
</div>
<!-- user feedback -->
