import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FreelancerService {
  lat:any = ''
  lan:any = ''
  category:any =''
  data:any []= []
  available:any = ''
  rate:any =  ''
  id:any =  ''
  constructor(private _http: HttpClient) { }
  // get all user
    getAllUser(){
      return this._http.get(`${environment.apiUrl}/api/user/freelancer?perPage=20&pagination=true`)
    }
  // get one user
    getOneUser(id:any){
      return this._http.get(`${environment.apiUrl}/api/user/freelancer?id=${id}`)
    }
    // add to favorite
    addRequestToFavorite(id: any, data: any) {
      return this._http.post(`${environment.apiUrl}/api/favourite/${id}`, data)
    }
  PaginationOfData(numberOfPag: number) {
    return this._http.get(`${environment.apiUrl}/api/user/freelancer?perPage=20&pagination=true&page=${numberOfPag}`)
  }
    // search
    searchData() {
      if (this.category == undefined) {
        this.category = ''
      }
      return this._http.post(`${environment.apiUrl}/api/user/freelancer?pagination=true&perPage=20&search=true&category=${this.category}&rate&lat=${this.lat}&lan=${this.lan}&available=${this.available}&rate=${this.rate}&role_id=${this.id}`, '')
    }
  // delete value of search
  searchDelete(id:any) {
    return this._http.get(`${environment.apiUrl}/api/basic/log/deleteSaveSearch?model=freelancer&id=${id}`)
  }
  // search data for tlbat by name
  searchByName(value:any) {
    return this._http.get(`${environment.apiUrl}/api/user/searchName?searchName=${value}`)
  }
  finalSearch(value: any) {
  return this._http.get(`${environment.apiUrl}/api/user/freelancer?pagination=true&search=true&search_value=${value}&perPage=20`)
  }
}
