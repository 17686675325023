
<div class="chat-page container-fluid bg-white-sup" >
  <div class="container px-0 mt-20 h-100">
    <div class="row py-md-4 height-chat flex-md-wrap-reverse ">
      <!-- chats list -->
      <div class="col-lg-4 col-md-5 h-100 chats-list mb-sm-3" >
        <div class="bg-dark border-l-radius-s12 h-100">
          <!-- chats header -->
          <div class="d-flex align-items-center gap-2 pt-4 p-3">
            <i class="ic-chat-icon iC-S32 d-flex img-Background-Cover"></i>
            <p class="m-0 font-Regular-s16 text-white">الرسائل</p>
          </div>
          <!-- chats list -->
          <div class="mian-chats-list bg-white border-l-radius-s12 py-4 px-1" style="height: calc(100% - 72px);">
            <!-- search input -->
            <div class="search-input">
              <div
                class="d-flex align-items-center gap-2 w-100 Border-light-grey border-f-radius-s12 bg-light-blue py-2 px-1">
                <i class="ic-all-request-Vector iC-S16 z-10 d-flex cursor-click img-Background-Contain"></i>
                <input matInput placeholder="أبحث عن ..." [formControl]="chatService.searchControl"
                  [matAutocomplete]="users" class="bg-light-blue w-100" aria-label="search">
              </div>
              <!-- Dropdown menu -->
              <mat-autocomplete #users="matAutocomplete">
                <mat-option *ngFor="let user of users$|async"
                  (click)="createChat(user)">{{user?.displayName}}</mat-option>
              </mat-autocomplete>
            </div>
            <!-- chat list -->
            <mat-selection-list [multiple]="false" [formControl]=" this.chatService.chatListControl"
              *ngIf="user$ | async as currentUser">
              <mat-list-option *ngFor="let chat of myChats$ | async" [value]="chat.id" class="mb-3 px-0">
                <div class="d-flex align-items-center gap-2" (click)="cardChat(chat.chatName)"
                  [class.new-message]="chat?.haveUnseenMessages && currentUser.uid !==chat?.lastMsgsenderId">
                  <img class="iC-S40 border-f-radius-s50" loading="lazy" alt="chatPic" matListAvatar [src]="getChatImg(chat.chatPic)" />
                  <div class="d-flex flex-column w-100">
                    <p mat-line class="chat-title d-flex justify-content-between m-0 gap-2 w-100">
                      <span class="chat-name text-bolder font-Bold-s14 text-dark-color">{{chat.chatName }}</span>
                      <!-- <span class="chat-date">{{chat.lastMessageDate | dateDisplay}}</span> -->
                      <span
                        class="chat-date font-Regular-s12 text-support2-color">{{chat.lastMessageDate|chatDate}}</span>
                      <span [class.new-message-sign]="chat?.haveUnseenMessages && currentUser?.uid !==chat?.lastMsgsenderId"></span>

                    </p>
                    <small class="font-Regular-s12 text-support2-color" mat-line>{{ chat.lastMessage
                      ? chat.lastMessage :
                      'making new message' }}</small>
                  </div>
                </div>
                <!-- horizontal line -->
                <div class="horizontal-line"></div>
              </mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>

      <!-- chat -->
      <div  class="col-lg-5 col-md-7 h-100 chat-body mb-sm-3">
        <div class="bg-dark border-l-radius-s12 h-100 " >
          <!-- chat header -->
          <div class="d-flex justify-content-between align-items-center w-100 p-3 pt-4"
            *ngIf="selectedChat$ | async as selectedChat ">
            <div class="d-flex align-items-center justify-content-between gap-2 w-100">
              <div class="d-flex align-items-center gap-2">
                <img class="border-f-radius-s12 iC-S32" loading="lazy" alt="chatPic"
                [src]="getChatImg(selectedChat.chatPic)" />
                <p class="m-0 font-Bold-s14 text-white">{{ selectedChat.chatName }}</p>
              </div>
            </div>
          </div>
          <!-- <ng-template #noChatSelected>
            <p class="m-0 font-Regular-s16 text-white p-4">اكتب رسالة جديدة</p>
          </ng-template> -->
          <!-- chat -->
          <div class="bg-white border-2-radius-s12 p-3" style="height: calc(100% - 72px);">
            <div class="chat-area">
              <ng-container *ngIf="user$ | async as currentUser">
                <div *ngFor="let message of messages$|async " class="chat-bubble-container"
                  [ngClass]="{ 'sender': message.senderId === currentUser.uid }">
                  <div class="image-area d-flex gap-2">
                    <img class="border-f-radius-s12 iC-S40 " loading="lazy" alt="chatPic" src="{{message.senderId === currentUser.uid?currentUserImage:userData?.avatar}}" />
                    <div class="chat-bubble">
                      <p class="m-0 mb-2" *ngIf="!message.text.includes('/files') && !message.text.includes('/images')">
                        {{
                        message.text }}
                      </p>
                      <a *ngIf="message.text.includes('/files')" [href]="message.text" target="_blank" class="mb-2"
                        aria-label="textFile">
                        <i class=" {{message.text.includes('.pdf') ? 'ic-request-details-Pdf' : message.text.includes('.docx') ? 'ic-request-details-Docs' : message.text.includes('.xls') ? 'ic-request-details-Xls' :'ic-request-details-other-documents'}}
                                            iC-S24 img-Background-Contain d-flex"></i>
                      </a>
                      <a *ngIf="message.text.includes('/images')" [href]="message.text" target="_blank" class="mb-2"
                        aria-label="textImage">
                        <img class="mb-2" [src]="message.text" alt="Image" loading="lazy" />
                      </a>
                      <span class="chat-time">{{message.sentDate | chatDate}}</span>
                      <p class="chat-seen position-absolute"
                        *ngIf="message.seen && message.senderId === currentUser.uid"><i
                          class="fa-solid fa-check-double text-seen "></i> </p>
                      <p class="chat-seen position-absolute"
                        *ngIf="!message.seen && message.senderId === currentUser.uid"><i
                          class="fa-solid fa-check-double text-unseen"></i></p>
                    </div>
                  </div>
                </div>
                <div #endOfChat></div>
              </ng-container>
            </div>
            <!-- chat input -->
            <div class="input-area">
              <!-- </div> -->
              <mat-form-field appearance="outline">
                <div class="d-flex bg-white-sup border-f-radius-s12 Border-light-grey">
                  <input matInput class="w-100  bg-white-sup px-3 py-2" placeholder="اكتب رسالتك ..."
                    [formControl]="messageControl" (keydown.enter)="sendMessage()" aria-label="message" />
                  <div class="d-flex">
                    <input type="file" id="input_file" hidden (change)="fileChange($event)" aria-label="input_file">
                    <button id="media" (click)="open_file()" aria-label="media"
                      class=" media border-0 uCC-f-radius-s15 d-flex align-items-center uCC-gap-8b cCC-bg-white">
                      <i class="ic-send-media iC-S20 imCC-Background-Contain d-flex"></i>
                    </button>
                    <button mat-icon-button matSuffix class="mx-3" (click)="sendMessage()" id="sendMessage"
                      aria-label="sendMessage">
                      <i class="ic-send-icon iC-S20 imCC-Background-Contain d-flex"></i>
                    </button>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="h-100 bg-white border-l-radius-s12 px-3 d-flex justify-content-center align-items-center" *ngIf="noChatSelected">
                          <img src="../../../../assets/Icons/chat/no-chat.svg" alt="" style="width: 200px;height: 200px;">
                      </div> -->
        </div>
      </div>
      <!-- Chat Resp -->

      <!-- card -->
      <div  class="col-lg-3 col-md-12 pb-3 mt-5 chat-card"  *ngIf="selectedChat$ | async as selectedChat">
        <div class="card-holder p-4 bg-white gap-3 d-flex flex-column align-items-center">
          <!-- user image -->
          <div class="position-relative h-100 px-0.5 py-1">
            <mat-progress-bar mode="determinate" class="position-absolute top-0 left-0"
              [value]="top"></mat-progress-bar>
            <mat-progress-bar mode="determinate" style="transform: rotateZ(270deg); bottom: 49%;"
              class="position-absolute right-1/2" [value]="right"></mat-progress-bar>
            <mat-progress-bar mode="determinate" style="transform: rotateZ(180deg);"
              class="position-absolute bottom-0 right-0" [value]="bottom"></mat-progress-bar>
            <mat-progress-bar mode="determinate" style="transform: rotateZ(90deg);bottom: 49%;"
              class="position-absolute left-1/2" [value]="left"></mat-progress-bar>
            <!-- user image -->
            <div class="changeAvatar">
              <img style="background-image: url({{userData?.avatar}});" loading="lazy"
                class="userImg-s border-f-radius-s12 relative img-Background-Contain">
              <i [ngClass]=" userData?.available == 1 ? 'ic-profile-online' :'ic-profile-offline'"
                class="d-flex iC-S16 img-Background-Cover position-absolute bottom-2 left-2"></i>
            </div>
          </div>
          <p class="m-0 text-dark-black font-Bold-s20 d-flex align-items-center">
            {{userData?.fullname}}
            <span><img *ngIf="userData?.nationality != null" class="iC-S20 ms-1.5" src="{{userData?.nationality?.logo}}"
                loading="lazy" alt="nationality logo"></span>
          </p>
          <p class="m-0 text-support2-color font-Regular-s14 d-flex align-items-center">
            <i class="d-flex mx-2 img-Background-Cover iC-S20 ic-profile-memo-circle-check"></i>
            {{userData?.job_name?.name}}
          </p>
          <p class="m-0 text-support2-color font-Regular-s14 d-flex align-items-center">
            <i class="d-flex mx-2 img-Background-Cover iC-S16 ic-all-request-location" style="height: 18px"></i>
            <span *ngIf="userData?.lat!== null && userData?.lan!== null">{{country}} {{city}}</span>
            <span *ngIf="userData?.lat== null && userData?.lan== null">{{location}}</span>
          </p>
        </div>
        <button *ngIf="selectedChat$ | async as selectedChat" id="showFile" aria-label="showFile"
          class="border-f-radius-s12 mt-4 w-100 font-Regular-s14 bg-white text-main-color py-2 px-3"
          (click)="userProfile(selectedChat.chatName)">عرض الملف الشخصي
        </button>
      </div>
    </div>
  </div>
</div>