import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, from, map } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Auth, authState, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '@angular/fire/auth';
import { Firestore, doc, setDoc, updateDoc } from '@angular/fire/firestore';
import { ProfileService } from './profile.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public displayDropdown: boolean = false
  private userSubject!: BehaviorSubject<any>;
  public user!: Observable<any>;
  public phoneProccess: any;
  public registerationForm: any;
  public avater: any;
  public freelancerType: any;
  public loginForm: any;
  public locationData: any;
  public displayProggressBar: boolean = false;
  public pPCustomWidth: string = ''
  public pPValue: number = 0;
  public displayInput: boolean = false
  public userName: any;
  public userFullName: any;
  public id: any;
  public userRole: any;
  public userImage: any;
  public userToken: any;
  public location:any
  public userData:any
  public latOfUser:any
  public lanOfUser:any
  public addressName:any
  public changePasswordId: number;
  public verificationChecked: Number = 0
  currentUser$ = authState(this.auth);
  constructor(private _http: HttpClient, private router: Router, private firestore: AngularFirestore, private auth: Auth, private fire: Firestore, private profile: ProfileService) {
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();
    // this.currentUser$.subscribe((user)=>user)
  }
  public get userValue(): any {
    return this.userSubject.value;;
  }
  checkMobileNumber(mobile: any) {
    return this._http.post(`${environment.apiUrl}/api/check_mobile/check`, mobile)
  }
  checkOtpCode(data: any) {
    return this._http.post(`${environment.apiUrl}/api/check_mobile/code`, data)
  }
  getNationalities() {
    return this._http.get(`${environment.apiUrl}/api/coredata/nationality/list`)
  }
  getCountries() {
    return this._http.get(`${environment.apiUrl}/api/coredata/country/list`)
  }
  getCities(country_id: any) {
    return this._http.get(`${environment.apiUrl}/api/coredata/city/list?country_id=${country_id}`)
  }
  getStates(country_id: any, city_id: any) {
    return this._http.get(`${environment.apiUrl}/api/coredata/state/list?country_id=${country_id}&city_id=${city_id}`)
  }
  gerAreas(country_id: any, city_id: any, state_id: any) {
    return this._http.get(`${environment.apiUrl}/api/coredata/area/list?country_id=${country_id}&city_id=${city_id}&state_id=${state_id}`)
  }
  checkUserName(userName: any) {
    return this._http.get(`${environment.apiUrl}/api/auth/check/username?username=${userName}`)
  }
  checkEmail(email: any) {
    return this._http.get(`${environment.apiUrl}/api/auth/check/email?email=${email}`)
  }
  // save User data
  saveUserData(userId: any, userData: any): Observable<any> {
    const userRef = this.firestore.collection('users').doc(userId);
    return from(userRef.set(userData, { merge: true }));
  }
  // Register
  register(data: any) {
    return this._http.post(`${environment.apiUrl}/api/auth/register`, data).pipe(map((user: any) => {
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }
  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null!);
    this.router.navigate(['/']);
    // this.logoutFire()
  }
  logoutFire() {
    return from(this.auth.signOut());
  }
  updateSettings(userId: any, data: any) {
    return this._http.post(`${environment.apiUrl}/api/user/update/${userId}`, data).pipe(map((user: any) => {
      if (user?.data?.lat !== null && user?.data?.lan !== null) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${user?.data?.lat},${user?.data?.lan}&language=ar&key=${environment.googleMapKey}`;
        this._http.get(url).subscribe((response: any) => {
          if (response.results && response.results.length > 0) {
            const addressComponents = response.results[0].address_components;
            for (const component of addressComponents) {
              if (component.types.includes('country')) {
                localStorage.setItem('location', JSON.stringify( component.long_name));
              }
            }
          }
        });
      }
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }
  convertAccount() {
    return this._http.post(`${environment.apiUrl}/api/user/convert/account`, '')
  }
  contactUsHome(data: any) {
    return this._http.post(`${environment.apiUrl}/api/setting/contact_us/store`, data)
  }
  // login
  login(data: any) {
    return this._http.post(`${environment.apiUrl}/api/auth/login`, data ).pipe(map((user: any) => {  
      if (user?.data?.lat !== null && user?.data?.lan !== null) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${user?.data?.lat},${user?.data?.lan}&language=ar&key=${environment.googleMapKey}`;
        this._http.get(url).subscribe((response: any) => {
          if (response.results && response.results.length > 0) {
            const addressComponents = response.results[0].address_components;
            for (const component of addressComponents) {
              if (component.types.includes('country')) {
                localStorage.setItem('location', JSON.stringify( component.long_name));
              }
            }
          }
        });
      }
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }
  signUp(email: string, password: string) {

    return from(createUserWithEmailAndPassword(this.auth, email, password));
  }
  signIn(email: string, password: string) {
    

    signInWithEmailAndPassword(this.auth, email, password)
    .then((data:any)=>{
      localStorage.setItem("firebase","false");
      from(data);
    })
    .catch((err:any)=>{
      if(err?.message?.indexOf('There is no user record corresponding to this identifier') >-1){
        localStorage.setItem("firebase","false");
        window.alert("هذا المستخدم غير مسجل في خدمه الرسائل");
      }
    })
    // return from(signInWithEmailAndPassword(this.auth, username, password));
  }
  closeDropdown() {
    $('.drop-down').removeClass('drop-down--active');
    $('#chat').addClass('d-none');

    this.displayDropdown = !this.displayDropdown
  }
  // Get UserName
  getUserName() {
    this.userName = JSON.parse(localStorage.getItem('user')!).data?.username;
  }
  getUserFullName() {
    this.userFullName = JSON.parse(localStorage.getItem('user')!).data?.fullname;
  }
  getUserId() {
    this.id = JSON.parse(localStorage.getItem('user')!).data?.id;
  }
  getUserRole() {
    this.userRole = JSON.parse(localStorage.getItem('user')!).data?.role?.name;

  }
  getUserImage() {
    this.userImage = JSON.parse(localStorage.getItem('user')!).data?.avatar;

  }
  getUserToken() {
    this.userToken = JSON.parse(localStorage.getItem('user')!).data?.token;
  }
  // For Making input Numbers Only
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  // forget password
  checkForgetEmail(data: any) {
    return this._http.post(`${environment.apiUrl}/api/forget_password/check`, data)
  }
  checkForgetOtpCode(data: any) {
    return this._http.post(`${environment.apiUrl}/api/forget_password/code`, data)
  }
  changePassword(data: any) {
    return this._http.post(`${environment.apiUrl}/api/forget_password/change_password`, data)
  }
  getUserInfo(){
      this.latOfUser = JSON.parse(localStorage.getItem('user')!).data?.lat
      this.lanOfUser = JSON.parse(localStorage.getItem('user')!).data?.lan
      this.location = JSON.parse(localStorage.getItem('location')!)
  }
  // uplod avater 
  uplodAvater(id: any , data:any) {
    return this._http.post(`${environment.apiUrl}/api/user/update/${id}`, data)
  }
}
