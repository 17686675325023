<div
  class="bg-white border-f-radius-s20 p-md-5 p-3 d-flex flex-column gap-3 position-relative h-100">
  <!-- back -->
  <div class="d-flex align-items-center gap-3 cursor-click mb-1" routerLink="/register/account-type">
    <!-- arrow left -->
    <div class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
      <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
    </div>
    <!-- back text -->
    <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
  </div>
  <div class="d-flex flex-column align-items-center gap-3">
    <!-- header -->
    <p class="m-0 font-Bold-s20 text-dark-color">يرجى تحديد نوع حساب الذي يصفك بشكل أفضل</p>
    <!-- description -->
    <p class="m-0 font-Regular-s14 text-light-black-color text-center md:w-1/2 line-height-15">
      تحديد نوع الحساب بشكل صحيح يسهل وصول عملائك لك
    </p>
    <!-- types holder -->
    <div class="mt-2 d-md-flex gap-4 md:w-9/12" >
      <!-- user type -->
      <div
        class="user_click Border-light-grey mb-3 mb-md-0 border-f-radius-s12 bg-transparent p-4 d-flex flex-column align-items-center justify-content-center gap-2"
        style="flex: 1;">
        <!-- freelancer icon -->
        <i class="ic-freelancer-account iC-S80 img-Background-Contain d-flex my-2"></i>
        <!-- freelancer account -->
        <p class="m-0 font-Bold-s18 text-dark-color">حساب مشتغل</p>
        <!-- freelancer description -->
        <p class="m-0 font-Regular-s14 text-support2-color text-center pb-2 line-height-2">
          
        </p>
      </div>
      <!-- freelancer account types -->
      <div class=" d-flex flex-md-column justify-content-between gap-md-4 gap-3" style="flex: 1;">
        <!-- freelancer type -->
        <div (click)="saveData(freelancer.id)" id="{{freelancer.id}}"
          class="cursor-click px-md-4 px-2 py-md-4 py-3 Border-light-grey bg-white border-f-radius-s12 d-md-flex justify-content-center gap-3 button-hover"
          *ngFor="let freelancer of freelancerTypesArray">
          <!-- freelancer icon -->
          <i class="{{freelancer.icon}} iC-S40 img-Background-Contain d-flex mx-auto mx-md-0 my-2" style="flex-shrink: 0;"></i>
          <div class="d-flex flex-column gap-2">
            <!-- type text -->
            <p class="m-0 mx-auto mx-md-0 font-Bold-s16 text-dark-color">{{freelancer.name}}</p>
            <!-- type desc -->
            <p class="m-0 font-Regular-s14 text-support2-color line-height-15">
              {{freelancer.description}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
