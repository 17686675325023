<div *ngIf='!this.activeRouter.url.includes("/request/edit-request/")
&& !this.activeRouter.url.includes("/request/Add-new-request")
&& !this.activeRouter.url.includes("/register")
&& !this.activeRouter.url.includes("/login")
&& !this.activeRouter.url.includes("Occupy-me")
&& !this.activeRouter.url.includes("/ads/add-new-advrties")
&& !this.activeRouter.url.includes("/forget-password")
&& !this.activeRouter.url.includes("/ads/edit-ads")
&& !this.activeRouter.url.includes("/chat")'
  class="container-fluid footer-shape img-cover bg-dark px-0 pt-4">
  <div class="container px-md-0 px-3 border-top-holder">
    <!-- Logo Image -->
    <i class="ic-Logo-Footer iC-dimensions-logo img-Background-Contain d-flex mb-4 cursor-click" routerLink="/"></i>
    <!-- Footer-Holder -->
    <div class="row pb-lg-5 pb-3 mb-2">
      <div class="col-lg-4 col-12">
        <p class="m-0 font-Regular-s14 line-height-2 text-white-color cursor-click" style="width: 95%;">
          مرحبا بك في [بمنصة شغل]، منصتك المفضلة لربط المستقلين الموهوبين بالعملاء الذين يبحثون عن خبراتهم. نهدف إلى إنشاء مجتمع
          مزدهر حيث يمكن للمهنيين التعاون وتنمية حياتهم المهنية وتحقيق أهدافهم المستقلة.
        </p>
      </div>
      <div class="col-lg-1 col-12 d-flex flex-column gap-3 mt-lg-0 mt-3 cursor-click">
        <!-- Menu -->
        <p class="m-0 font-Regular-s16 text-white-color break-words flex-wrap" *ngFor="let text of firstMenuList" routerLink="{{text.router}}">
          {{text.title}}
        </p>
      </div>
      <div class="col-lg-1 col-12"></div>
      <div class="col-lg-1 col-12 d-flex flex-column gap-3 mt-lg-0 mt-3">
        <!-- Menu -->
        <p class="m-0 font-Regular-s16 text-white-color text-nowrap cursor-click break-words flex-wrap" *ngFor="let text of secondMenuList">
          {{text.title}}
        </p>
      </div>
      <div class="col-lg-2 col-12"></div>
      <div class="col-lg-3 col-12 p-0 d-flex flex-column gap-3 mt-lg-0 mt-3 px-lg-0 px-3">
        <p class="m-0 font-Bold-s16 text-white-color">التواصل الأجتماعي</p>
        <div class="d-flex gap-3">
          <a class="cursor-click" aria-label="social" href="{{socialMedia.link}}" *ngFor="let socialMedia of socialMediaIconsArray"
            target="_blank">
            <!-- social media icons -->
            <i class="{{socialMedia.icon}} iC-S20 img-Background-Contain d-flex cursor-click"></i>
          </a>
        </div>
        <p class="m-0 font-Bold-s16 text-white-color mt-2">تطبيق المنصة من هنا</p>
        <div class="d-flex align-items-center justify-content-lg-between gap-3">
          <i class="ic-App-Store-icon app-holder img-Background-Contain d-flex cursor-click"></i>
          <i class="ic-Google-Play-icon app-holder img-Background-Contain d-flex cursor-click"></i>
        </div>
      </div>
    </div>

  </div>
  <!-- sub footer copyright -->
  <div class="w-100 d-flex justify-content-center py-lg-4 py-3">
    <!-- Privacy Policy -->
    <p class="m-0 font-Regular-s14 text-white-color text-center">
      Copyright ©2023 All rights reserved | Shogol Platform by lun startup studio
    </p>
  </div>
</div>
