import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.scss']
})
export class EditOfferComponent {
  @Input() freelancer:any;
  @Input() editFormFunction:any;
  @Output() newItemEvent = new EventEmitter<string>();

  constructor(public _AuthService: AuthService, private router: Router){}
  
}
