import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, map, startWith} from 'rxjs';
import { UsersService } from 'src/app/core/services/users.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { PopupChatService } from '../context-menu/services/popup-chat.service';
import {Firestore,collection,onSnapshot,query,where} from '@angular/fire/firestore';
declare var $: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent {
  searchControl = new FormControl('');
  chatListControl = new FormControl('');
  currentPopUpChatData:any
  user$ = this.usersService.currentUserProfile$;
  myChats$ = this.chatsService.myChats$;

  otherUsers$ = combineLatest([this.usersService.allUsers$, this.user$]).pipe(
    map(([users, user]) => users.filter((u) => u.uid !== user?.uid))
  );

  users$ = combineLatest([
    this.otherUsers$,
    this.searchControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    map(([users, searchString]) => {
      return users.filter((u) =>
        u.displayName?.toLowerCase().includes(searchString.toLowerCase())
      );
    })
  );

  constructor(
    public _AuthService: AuthService,
    public activeRouter: Router,
    public usersService: UsersService,
    public _SnackBarService: SnackBarService,
    public chatsService: ChatService,
    private popupChatService:PopupChatService,
    private firestore: Firestore
    ) {}

    ngOnInit(): void {    
    }

    openChatPopup(event:MouseEvent) {
      this.popupChatService.meta$.next({
        opened: true,
        event,
        data: this.currentPopUpChatData,
        onCloseMenu: this.freePopUpChat.bind(this),
      });
    }

    openComponentChatPopUp( event:MouseEvent,data: any) {
        const ref = collection(this.firestore, 'users');
        const q = query( ref,where('displayName', '==', data.chatName?data.chatName:data.displayName));
        onSnapshot(q ,(snapshot)=>{
          snapshot.forEach((user)=>{this.currentPopUpChatData=user.data();this.openChatPopup(event)})
        })
    }

    freePopUpChat() {
      this.currentPopUpChatData = null;
    }

    openChatsPopUp(){
      if ($(".chat_container").is(":visible")) {
        $(".arrow-direction").addClass("ic-caret-up-icon")
        $(".arrow-direction").removeClass("ic-caret-down-icon")
      } else {
        $(".arrow-direction").addClass("ic-caret-down-icon")
        $(".arrow-direction").removeClass("ic-caret-up-icon")
      }
      $(".chat_container").slideToggle("slow")
      return false
    }

    getChatImg(img:any){
      return typeof(img) !="object" && img? img : '../../../../assets/Icons/chat/user-placeholder.svg'
    }
}
