import { Injectable } from '@angular/core';
import { Firestore, collection, collectionData, doc, docData, query, setDoc } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { Observable, from, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private firestore: Firestore,
    private authService: AuthService
  ) { }

  get currentUserProfile$(): Observable<any | null> {
    return this.authService.currentUser$.pipe(
      switchMap((user) => {
        if (!user?.uid) {
          return of(null);
        }

        const ref = doc(this.firestore, 'users', user?.uid);
        return docData(ref) as Observable<any>;
      })
    );
  }
  get allUsers$(): Observable<any[]> {
    const ref = collection(this.firestore, 'users');
    const queryAll = query(ref);
    return collectionData(queryAll) as Observable<any[]>;
  }

  addUser(user: any): Observable<any> {
    localStorage.setItem("uid",user.uid)
    const ref = doc(this.firestore, 'users', user?.uid);
    return from(setDoc(ref, user));
  }
  updateUser(){

  }
}
