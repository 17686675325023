import { HttpClient ,HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  lat:any = ''
  lan:any = ''
  price:any = ''
  name_user:any =''
  rate_count:any =''
  rate:any =''
  category:any = ''
  available:any= ''
  roleId:any=''
  progress: any[] = [] ;
  freelancer:any=''
  company:any =''
  data:any []=[]
  constructor(private _http: HttpClient) { }
  stopReceivingOffers(id:any){
    return this._http.get(`${environment.apiUrl}/api/ad/change_status_offer/${id}`)
  }
  DeleteOffers(id:any ){
    return this._http.delete(`${environment.apiUrl}/api/ad/delete/${id}`)
  }
  getAdstDetails(requestId:any){
    return this._http.get(`${environment.apiUrl}/api/ad/show/${requestId}`)
  }
  editAds(id:any , data:any){
    return this._http.post(`${environment.apiUrl}/api/ad/update/${id}` , data)
  }
  deletAds(id:any){
    return this._http.post(`${environment.apiUrl}/api/ad/deleteImage/${id}` , '')
  }
  ReceivingOffers(id:any){
    return this._http.get(`${environment.apiUrl}/api/ad/change_status_offer/${id}`)
  }

  addAdsToFavorite(id: any, data:any) {
    return this._http.post(`${environment.apiUrl}/api/favourite/${id}`, data)
  }
  getAllAds(){
    return this._http.post(`${environment.apiUrl}/api/ad/list?perPage=20&pagination=true&search=true`, '')
  }
  DataAds(){
    return this._http.get(`${environment.apiUrl}/api/ad/list?perPage=20&pagination=true`)

  }
  paginationOfAds(numberpage:number){
    return this._http.get(`${environment.apiUrl}/api/ad/list?perPage=20&pagination=true&page=${numberpage}&search=true`)
  }
  searchAds(){
    if (this.category == undefined) {
      this.category = ''
    }
    return this._http.get(`${environment.apiUrl}/api/ad/list?perPage=20&price=${this.price}&name_user=${this.name_user}&lat=${this.lat}&lan=${this.lan}&rate_count=${this.rate_count}&rate=${this.rate}&category=${this.category}&available=${this.available}&freelancer=${this.freelancer}&compnay=${this.company}`)
  }
  // create new advrties
  createNewAdvirtes(data:any){
    return this._http.post(`${environment.apiUrl}/api/ad/store`, data)
  }
  // edit advrties
  editAdvirtes(data:any , id:any){
    return this._http.post(`${environment.apiUrl}/api/ad/update/${id}`, data)
  }
  // edit advrties
  deleteAdvirtes( id:any){
    return this._http.delete(`${environment.apiUrl}/api/ad/delete/${id}`)
  }
  similarAds( category:any , adId:any){
    return this._http.get(`${environment.apiUrl}/api/ad/list?pagination=true&category=${category}&without=${adId}`)
  }
  // delete value of search
  searchDelete(id:any) {
    return this._http.get(`${environment.apiUrl}/api/basic/log/deleteSaveSearch?model=ad&id=${id}`)
  }
  // search data for tlbat by name
  searchByName(value: any) {
    return this._http.get(`${environment.apiUrl}/api/ad/searchName?searchName=${value}`)
  }
  finalSearch(value:any){
    return this._http.post(`${environment.apiUrl}/api/ad/list?pagination=true&search=true&search_value=${value}`, '')
  }
  uplodFile(file:any , i:number){
    this._http.post(`${environment.apiUrl}/api/ad/storeImage`, file, {
      reportProgress: true,
      observe: "events"
    }).pipe(
      //  progress bar
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress[i].progres = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          // this.progress = null;
        }
      }),
      catchError((err: any) => {
        this.progress[i].progres = null;
        alert(err.message);
        return throwError(err.message);
      })
    )
    .toPromise();
  }
}

