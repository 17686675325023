<div class="d-flex img-S img-header img-Background-Cover border-B-radius-s20 z-10 mt-16 ">
  <!-- image for header -->
  <!-- <i class="iM-S iM-header  position-absolute -top-1.5 border-B-radius-s20"></i> -->
  <div class="container-md iM-S px-0">
    <div class="d-flex  h-100 flex-column justify-content-between align-items-md-center align-items-start px-md-5">
      <div class="mt-4 w-75 px-md-0 px-4">
        <p class="m-0 font-Regular-s16 text-white-color">أبحث عن</p>
        <div class="caption">
          <div class="text-box">
            <p class="m-0 font-Bold-s28 text-main-color">اعلانات المشتغلين...</p>
            <p class="m-0 font-Bold-s28 text-main-color">طلبات المستخدمين...</p>
            <p class="m-0 font-Bold-s28 text-main-color">السيرة الذكية للمشتغلين...</p>
          </div>
        </div>
      </div>
      <!-- input for search -->
      <div class="d-flex flex-column justify-content-md-between h-100 justify-content-start w-100 px-md-0 px-3">
        <div
          class="d-flex py-0.5 justify-content-center~ border-f-radius-s15 Border-light-grey mx-auto bg-light-white align-items-center md:w-3/4 w-full">
          <div class="relative d-flex w-100 align-items-center">

            <div class="z-40 relative d-flex align-items-center w-100 bg-light-white border-f-radius-s15">
              <i class="ic-all-request-Vector iC-S20 p-2 ms-2 me-3 d-flex cursor-click img-Background-Contain" (click)="openSaved()"></i>
              <input class="font-Regular-s16 py-2.5 w-100 mx-md-2 bg-light-white text-support2-color border-f-radius-s15" (keyup.enter)="finalSearch()" [(ngModel)]="valueSearch" aria-label="search"
              placeholder="أبحث عن ..."  (click)="saveSearch(this._routerActive.url.split('/')[2]) ;openSaved()">
              <button class="border-0 border-f-radius-s12 text-white-color z-10 bg-main mx-2 px-5 py-2 font-Bold-s16 d-lg-block d-none" (click)="finalSearch()" 
                style="z-index: 1;" id="search" aria-label="search">
                  بحث
              </button>
            </div>
            <div [@openClose]="isOpen ? 'open' : 'closed'" class="open-close-container border-b-radius-s12 bg-white tree
            position-absolute top-8 bottom-0 left-0 right-0 z-20 box-shadow" (click)="openSaved()"
            *ngIf="searchValue?.length !== 0">
            <div *ngFor="let item of searchValue" class="cursor-click d-flex align-items-center p-4 pb-0">
            <i class="d-flex mx-2 img-Background-Cover iC-S16 ic-all-request-time"></i>
            <p  class="m-0 font-Regular-s16 d-flex align-items-center justify-between w-100">
              <span (click)="finalSearch(item.value)">
                {{item?.value}}
              </span>
              <i (click)="searchDelete(item?.id)" class="d-flex mx-2 img-Background-Cover iC-S12 ic-remove"></i>
              </p>
            </div>
          </div>
        </div>
          <!-- responsive -->
          <button id="request" aria-label="request" type="button" data-bs-toggle="offcanvas" data-bs-target="#requestFilterOffcanvas" *ngIf='this._routerActive.url.includes("/request")'
            aria-controls="offcanvasBottom" class="border-0 border-f-radius-s12 text-white-color z-10 bg-main mx-2 px-2 py-2 d-lg-none d-block"
            style="z-index: 1;">
              <i class="ic-filter-whites-responsive iC-S16 d-flex cursor-click img-Background-Contain"></i>
          </button>
          <button id="ads" aria-label="ads" type="button" data-bs-toggle="offcanvas" data-bs-target="#adFilterOffcanvas" *ngIf='this._routerActive.url.includes("/ads")'
            aria-controls="offcanvasBottom" class="border-0 border-f-radius-s12 text-white-color z-10 bg-main mx-2 px-2 py-2 d-lg-none d-block"
            style="z-index: 1;">
              <i class="ic-filter-whites-responsive iC-S16 d-flex cursor-click img-Background-Contain"></i>
          </button>
          <button id="freelancer" aria-label="freelancer" type="button" data-bs-toggle="offcanvas" data-bs-target="#freelancerFilterOffcanvas" *ngIf='this._routerActive.url.includes("/freelancer")'
            aria-controls="offcanvasBottom" class="border-0 border-f-radius-s12 text-white-color z-10 bg-main mx-2 px-2 py-2 d-lg-none d-block"
            style="z-index: 1;">
              <i class="ic-filter-whites-responsive iC-S16 d-flex cursor-click img-Background-Contain"></i>
          </button>
        </div>
      </div>
      <!-- navbar -->
      <ul class="d-flex mb-1.5 relative w-75 gap-5 px-4">
        <!-- Navbar elements -->
        <li class="nav-item">
          <button id="allAds" aria-label="allAds" class="nav-link cursor-click d-flex align-items-center relative" [routerLink]="['/ads/all-ads']"
            routerLinkActive="active">
            <p class="m-0 font-Regular-s18 text-white-color">الإعلانات</p>
            </button>
          </li>
          <li class="nav-item">
            <button id="allReq" aria-label="allReq" class="nav-link cursor-click d-flex align-items-center relative" [routerLink]="['/request']"
            routerLinkActive="active">
            <p class="m-0 font-Regular-s18 text-white-color">الطلبات</p>
            </button>
          </li>
          <li class="nav-item">
            <button id="allUser" aria-label="allUser" class="nav-link cursor-click d-flex align-items-center relative" [routerLink]="['/freelancer']"
            routerLinkActive="active">
            <p class="m-0 font-Regular-s18 text-white-color">المشتغلين</p>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
