import { Component, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { OfferService } from 'src/app/core/services/offer.service';
import { RequestService } from 'src/app/core/services/request.service';
declare var $: any;
@Component({
  selector: 'app-request-offer-popup',
  templateUrl: './request-offer-popup.component.html',
  styleUrls: ['./request-offer-popup.component.scss']
})
export class RequestOfferPopupComponent {
  @ViewChild('OfferRequestForm', { static: false }) OfferRequestForm!: NgForm;
  constructor(private _ActivatedRoute: ActivatedRoute, private _OfferService: OfferService, public _AuthService: AuthService, public _RequestService: RequestService ) { }
  ngOnInit(): void {


  }
  showRequestDetails() {
    this._RequestService.getRequestDetails(`${+this._ActivatedRoute.snapshot.url[1].path}`).subscribe((res: any) => {
          
    })
  }
  ngSubmit(formValue: any) {
    let data = {
      price: formValue.price,
      time: formValue.time,
      description: formValue.description,
      task_id: +this._ActivatedRoute.snapshot.url[1].path,
    }
    this._AuthService.displayProggressBar = true;
    this._OfferService.addOffer(data).subscribe((res: any) => {
        this._AuthService.displayProggressBar = false;
        this._OfferService.displayOffer = true;
      this._OfferService.displayStatus = true;
      this._OfferService.displayOfferPopup = false;
      this.OfferRequestForm.resetForm();
      $('#requestOffer').modal('hide');
    } , err=>{
      this._AuthService.displayProggressBar = true;
    })

  }

}
