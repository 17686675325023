<div style="z-index: 10000000">
  <!-- selected chat  -->
<div class="l_c_h_chat box-F-shadow" dir="rtl" id="chat" *ngIf='!this.activeRouter.url.includes("/register") && !this.activeRouter.url.includes("/forget-password")&& !this.activeRouter.url.includes("/chat")'>
  <div class="c_h-chat" (click)="togleChatTap()">
    <!-- chat header -->
    <div class="left_c d-flex justify-content-between align-items-center w-100 px-3 py-2"
      *ngIf="selectedChat">
      <div class="d-flex align-items-center gap-2">
        <!-- getChatImg(selectedChat.chatPic) -->
        <img class="border-f-radius-s12 iC-S32" loading="lazy" alt="user-placeholder" [src]="getChatImg(selectedChat.chatPic)" />
        <p class="m-0 font-Bold-s14 text-white">{{ selectedChat.chatName }}</p>
      </div>
      <i class="ic-close iC-S16 d-flex img-Background-Cover arrow-direction" (click)="close()"></i>
    </div>
    <!--  -->
    <!-- <div class="right right_c" style="width:35px;">
      <a href="#" class="logout" title="End chat" name="" style="display:none;"><img src="chat/logout.png"></a>
    </div> -->
  </div>
  
  <div class="slected_chat_container position-relative px-3 pt-2" style="display: none;height:542px;">
    <!-- chat messages -->
    <div class="chat-area">
      <ng-container *ngIf="user$ | async as currentUser">
        <ng-container *ngIf="allMessages">
        <div *ngFor="let message of allMessages" class="chat-bubble-container position-relative"
          [ngClass]="{ 'sender': message.senderId === currentUser.uid }">
            <div class="image-area d-flex gap-2">
              <img class="border-f-radius-s12 iC-S40 " loading="lazy" alt="user-placeholder" [src]="
                message.chatPic ? message.chatPic : '../../../../assets/Icons/chat/user-placeholder.svg'
                " />
              <div class="chat-bubble">
                <p class="m-0 mb-2" *ngIf="!message.text.includes('/files') && !message.text.includes('/images')">{{
                  message.text }}
                </p>
                <a *ngIf="message.text.includes('/files')" [href]="message.text" target="_blank" class="mb-2" aria-label="messageFile">
                  <i class=" {{message.text.includes('.pdf') ? 'ic-request-details-Pdf' : message.text.includes('.docx') ? 'ic-request-details-Docs' : message.text.includes('.xls') ? 'ic-request-details-Xls' :'ic-request-details-other-documents'}}
                  iC-S24 img-Background-Contain d-flex"></i>
                </a>
                <a *ngIf="message.text.includes('/images')" [href]="message.text" target="_blank" class="mb-2" aria-label="messageImag">
                  <img loading="lazy" alt="message-text" class="mb-2" [src]="message.text" alt="Image" />
                </a>
                <span class="chat-time">{{message.sentDate.toMillis() | date}}</span> <!-- | dateDisplay -->
                <p class="chat-seen position-absolute" *ngIf="message.seen && message.senderId === currentUser.uid"><i class="fa-solid fa-check-double text-seen "></i> </p>
                <p class="chat-seen position-absolute" *ngIf="!message.seen && message.senderId === currentUser.uid"><i class="fa-solid fa-check-double text-unseen"></i></p>

              </div>
            </div>
        </div>
        <div #endOfChat></div>
      </ng-container>
      </ng-container>
    </div>
    <!-- chat input -->
    <div class="input-area">
      <!-- </div> -->
      <mat-form-field appearance="outline">
        <div class="d-flex bg-white-sup border-f-radius-s12 Border-light-grey">
          <input matInput class="w-100  bg-white-sup px-3 py-2" placeholder="اكتب رسالتك ..."
            [formControl]="messageControl" (keydown.enter)="sendMessage()"  aria-label="message" />
          <div class="d-flex">
            <input type="file" id="input_file" hidden (change)="fileChange($event)" aria-label="file" >
            <button id="media" (click)="open_file()" aria-label="media"
              class=" media border-0 uCC-f-radius-s15 d-flex align-items-center uCC-gap-8b cCC-bg-white">
              <i class="ic-send-media iC-S20 imCC-Background-Contain d-flex"></i>
            </button>
            <button id="send" mat-icon-button matSuffix class="mx-3" (click)="sendMessage()"
            aria-label="send">
              <i class="ic-send-icon iC-S20 imCC-Background-Contain d-flex"></i>
            </button>
          </div>
        </div>
      </mat-form-field>
    </div>
  </div>
</div>
</div>
