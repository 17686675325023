import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AdsService } from 'src/app/core/services/ads.service';
import { FreelancerService } from 'src/app/core/services/freelancer.service';
import { RequestService } from 'src/app/core/services/request.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

declare var $ :any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        overflow: 'hidden',
        height: '200px',
      })),
      state('closed', style({
        overflow: 'hidden',
        height: '0px',
      })),
      transition('open => closed', [
        animate('.2s')
      ]),
      transition('closed => open', [
        animate('.2s')
      ]),
    ]),
  ],
})
export class HeaderComponent{
  isOpen:boolean = false;
  @Input() offcanvasName:any;
  @Output() value = new EventEmitter<any>()
  valueSearch:string
  searchValue:any [] = [];
  constructor(private _request: RequestService, private _ads: AdsService, private _freelancer: FreelancerService, public _routerActive:Router,
    private elemRef: ElementRef<any>){}

  saveSearch(model:any){
    if(model == 'all-request'){
      this._request.saveSearchData('task').subscribe((res:any) => {
        this.searchValue = res.data;     
      })
    }if(model == 'all-ads'){
      this._request.saveSearchData('ad').subscribe((res:any) => {
        console.log(res)
        for(let i = 0 ; i < res.data.length ;i++){
          this.searchValue = res.data;
        }
    })
    }if(model == 'all-freelancer'){
      this._request.saveSearchData('freelancer').subscribe((res:any) => {
        for(let i = 0 ; i < res.data.length ;i++){
          this.searchValue = res.data.data;
        }
      })
    }
  }
  @HostListener('document:click', ['$event'])
  handleClick(e: MouseEvent) {
    if(this.isOpen == true) {
      this.isOpen = false
    }
  }
  openSaved() {
    setTimeout(() => {
      this.isOpen = true
    }, 1);
  }

  finalSearch(event?: any) {
   // this.valueSearch = event
      if (this._routerActive.url.includes("/request/all-request")){
        this._request.finalSearch(this.valueSearch).subscribe((res:any) => {
          this.value.next(res)
        })
      } if (this._routerActive.url.includes("/ads/all-ads")) {
        this._ads.finalSearch(this.valueSearch).subscribe((res:any) => {
          this.value.next(res)
        })
      } if (this._routerActive.url.includes("/freelancer/all-freelancer")) {
        this._freelancer.finalSearch(this.valueSearch).subscribe((res:any) => {
          this.value.next(res)
        })
      }
  }
  
  searchDelete(id:any){
    if(this._routerActive.url.includes("/freelancer/all-freelancer")){
        this._freelancer.searchDelete(id).subscribe(res => {
          this.saveSearch('all-freelancer')
        })
    } if(this._routerActive.url.includes("/ads/all-ads")){
      this._ads.searchDelete(id).subscribe(res => {
        this.saveSearch('all-ads')
      })
    } if(this._routerActive.url.includes("/request/all-request")){
      this._request.searchDelete(id).subscribe(res => {
        this.saveSearch('all-request')
      })
    }
  }

  // search(event: any) {
  //   if (this._routerActive.url.includes("/request/all-request")){
  //       if(event.value.length > 2){
  //       this._request.searchByName(event.value).subscribe((res:any)=>{
  //         this.searchValue = res.data.data
  //       })
  //     }else if(event.value.length < 3) {
  //         this.saveSearch('all-request')
  //     }
  //     } if (this._routerActive.url.includes("/ads/all-ads")) {
  //       if (event.value.length > 2) {
  //       this._ads.searchByName(event.value).subscribe((res: any) => {
  //         this.searchValue = res.data.data
  //       })
  //     }else if(event.value.length < 3) {
  //         this.saveSearch('all-ads')
  //     }
  //     } if (this._routerActive.url.includes("/freelancer/all-freelancer")) {
  //       if (event.value.length > 2) {
  //       this._freelancer.searchByName(event.value).subscribe((res: any) => {
  //         this.searchValue = res.data.data
  //       })
  //     }else if(event.value.length < 3) {
  //         this.saveSearch('all-freelancer')
  //     }
  //   }
  // }

  search() {
    console.log(this.valueSearch)
    if (this._routerActive.url.includes("/request/all-request")){
      this._request.finalSearch(this.valueSearch).subscribe((res:any)=>{
       // this.searchValue = res.data.data
        this.value.next(res)
      });
    }

    if (this._routerActive.url.includes("/ads/all-ads")) {
      this._ads.searchByName(this.valueSearch).subscribe((res: any) => {
        console.log(res)
        this.searchValue = res.data;
      });
    }
    if (this._routerActive.url.includes("/freelancer/all-freelancer")) {
      this._freelancer.searchByName(this.valueSearch).subscribe((res: any) => {
        this.searchValue = res.data;
      });
    }
  }

}


