<div class="bg-white border-f-radius-s20 px-3 py-3 px-lg-0 pt-md-5 lg:pb-24  d-flex flex-column align-items-center justify-content-between gap-3 h-100">
  <div class="d-flex flex-column align-items-center gap-md-3 py-3 pb-lg-0">
    <!-- header -->
    <p class="m-0 font-Bold-s20 text-dark-color">يرجى تحديد نوع الحساب</p>
    <!-- description -->
    <p class="m-0 font-Regular-s14 text-light-black-color text-center  lg:w-1/2 line-height-15" [ngClass]="{'mt-2': this._BreakPointService.isMobile == true}">
      يرجى تحديد نوع المستخدم الذي يتماشى مع أهدافك والنقر فوق الخيار المقابل. نحن هنا لدعمك في مساعيك المستقلة، مهما
      كان
      المسار الذي اخترته.
    </p>
    <!-- type cards -->
    <form class="mt-2 d-flex gap-4 gap-y-3 lg:w-3/4" [ngClass]="{'flex-column': this._BreakPointService.isMobile == true}">
      <!-- user type -->
        <ng-container>
        <input type="radio" id="user_Id" class="d-none" name="role_id" ngModel value="2" [(ngModel)]="roleId"
          (change)="accountRole()" aria-label="role_id">
        <button id="user_click" aria-label="user_click"
          class="user_click cursor-click Border-light-grey border-f-radius-s12 bg-transparent p-md-4 p-2 d-flex flex-column align-items-center align-items-center gap-2"
          style="flex: 1;">
          <!-- user icon -->
          <i class="ic-user-account iC-S80 img-Background-Contain d-flex my-2"></i>
          <!-- user account text -->
          <p class="m-0 font-Bold-s18 text-dark-color">حساب مستخدم</p>
          <!-- user account description -->
          <p class="m-0 font-Regular-s14 text-support2-color text-center pb-2 line-height-2">
            تحديد هذا الخيار. بصفتك عميلا، يمكنك من انجاز اشغالك من خلال نشر طلبك الى المشتغلين المستعدين لخدمتك او مراجعة ملفات تعريف المشتغلين والمشاركة في عملية التوظيف للعثور على المطابقة المثالية لاحتياجات مشروعك
          </p>
        </button>
      </ng-container>
      <!-- freelancer type -->
      <ng-container>
        <input type="radio" id="freelancer_Id" name="role_id" value="1" class="d-none" ngModel [(ngModel)]="roleId"
          (change)="accountRole()" aria-label="role_id">
        <button id="freelancer_click" aria-label="freelancer_click"
          class="freelancer_click cursor-click Border-light-grey border-f-radius-s12 bg-transparent p-md-4 p-2 d-flex flex-column align-items-center align-items-center gap-2"
          style="flex: 1;">
          <!-- freelancer icon -->
          <i class="ic-freelancer-account iC-S80 img-Background-Contain d-flex my-2"></i>
          <!-- freelancer account -->
          <p class="m-0 font-Bold-s18 text-dark-color">حساب مشتغل</p>
          <!-- freelancer description -->
          <p class="m-0 font-Regular-s14 text-support2-color text-center pb-2 line-height-2">
            اذا كنت فرد/منشآه وتتطلع الى زيادة مصادر دخلك وابراز خدماتك و مهاراتك من خلال السيرة الذكية، وتريد تقديم خدماتك للعملاء على منصتنا ، فاختر هذا الخيار. بصفتك مشتغل ستتاح لك جميع خدمات المنصة
          </p>
        </button>
      </ng-container>
    </form>
  </div>
  <!-- login text -->
  <div class="mt-auto d-flex flex-column gap-4 mb-lg-5 mb-3">
    <!-- horizontal line -->
    <div class="horizontal-line bg-light-grey"></div>
    <!-- login page -->
    <p class="m-0 d-flex justify-content-center align-items-center gap-1">
      <span class="font-Regular-s14 text-support2-color"> لديك حساب بالفعل</span>
      <span class="font-Bold-s16 text-dark-color cursor-click text-underline-hover" routerLink="/login">تسجيل الدخول</span>
    </p>
  </div>
</div>

