import { LowerCasePipe } from '@angular/common';
import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationOptions, NumberResult, OutputOptionsEnum } from 'intl-input-phone';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-forget-password-new-password',
  templateUrl: './forget-password-new-password.component.html',
  styleUrls: ['./forget-password-new-password.component.scss']
})
export class ForgetPasswordNewPasswordComponent {
  @ViewChild('forgetPasswordForm', { static: false }) forgetPasswordForm!: NgForm;

  errMessage: string = ''
  passwordIcon = 'ic-hidden';
  showPasswordConfirmationIcon = 'ic-hidden';
  showPassword: boolean = false;
  showPasswordConfirmation: boolean = false;
  constructor(private formBuilder: FormBuilder, public activeRouter: Router, 
    public _toasterService: ToasterService, public _AuthService: AuthService, public usersService: UsersService)
  {}

  // hide password function
  hidePassword() {
    this.showPassword = !this.showPassword
    switch (this.showPassword) {
      case false:
        this.passwordIcon = 'ic-hidden'
        break;
      case true:
        this.passwordIcon = 'ic-eyes'
        break;
    }
  }
  hidePasswordConfirmation() {
    this.showPasswordConfirmation = !this.showPasswordConfirmation
    switch (this.showPasswordConfirmation) {
      case false:
        this.showPasswordConfirmationIcon = 'ic-hidden'
        break;
      case true:
        this.showPasswordConfirmationIcon = 'ic-eyes'
        break;
    }
  }
  ngSubmit(formValue: any) {
    if (formValue.password  !== formValue.password_confirmation){
      this._toasterService.showError('');
    }else {
      this._AuthService.loginForm = {
        ...this._AuthService.loginForm,
        user_id:this._AuthService?.changePasswordId,
        password: formValue.password ,
        password_confirmation: formValue.password_confirmation
      }
      this._AuthService.displayProggressBar = true;
      this._AuthService.changePassword(this._AuthService.loginForm).subscribe((res:any)=>{
        if(res?.status  == 1 ){
          this._AuthService.displayProggressBar = false;
          this._toasterService.showSuccess('تم ارسال البيانات');
          this.activeRouter.navigate(['/login']);
          this._AuthService.verificationChecked = 0
        }
      }, err => {
        this._toasterService.showError('');
        this._AuthService.displayProggressBar = true;
      })
    }
  }
}
