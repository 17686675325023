import { AllAdsComponent } from './features/components/ads/pages/all-ads/all-ads.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './features/components/home-page/home-page.component';
import { AuthGuard } from './core/helpers/guards/auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { ForgetPasswordComponent } from './shared/components/forget-password/forget-password.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'ads',
    pathMatch: 'full'
  },
  {
    path: "home",
    component: HomePageComponent,
    title: "شغل | منصة العمل الحر"
  },
  {
    path: 'request',
    loadChildren: () => import('./features/components/requests/requests.module').then((m: any) => m.RequestsModule),
    title: "طلبات ومشاريع | منصة شغل"
  },
  {
    path: 'ads',
    loadChildren: () => import('./features/components/ads/ads.module').then((m: any) => m.AdsModule),
    title: "إعلانات وخدمات | منصة شغل"

  },
  {
    path: 'profile/:id',
    loadChildren: () => import('./features/components/profile/profile.module').then((m: any) => m.ProfileModule), canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./features/components/regesteration/regesteration.module').then((m: any) => m.RegesterationModule),
  },
  {
    path: 'freelancer',
    loadChildren: () => import('./features/components/freelancer/freelancer.module').then((m: any) => m.FreelancerModule),
    title: "مشتغلين ومستقلين في العالم العربي | منصة شغل"
  },
  {
    path: 'chat',
    loadChildren: () => import('./features/components/chat-page/chat-page.module').then((m: any) => m.ChatPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./features/components/auth/auth.module').then((m: any) => m.AuthModule),
    title: "تسجيل الدخول | منصة شغل"
  },
  {
    path: 'settings',
    loadChildren: () => import('./features/components/settings/settings.module').then((m: any) => m.SettingsModule),
    canActivate: [AuthGuard]
  },
  { path: "forget-password", component: ForgetPasswordComponent },

]



@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
