import { FreelancerTypeComponent } from './pages/freelancer-type/freelancer-type.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccountTypeComponent } from "./pages/account-type/account-type.component";
import { RegesterationComponent } from "./regesteration.component";
import { AccountInfoComponent } from "./pages/account-info/account-info.component";
import { OtpVerificationComponent } from "./pages/otp-verification/otp-verification.component";
import { LocationMapComponent } from "./pages/location-map/location-map.component";
import { FreelancerInfoComponent } from './pages/freelancer-info/freelancer-info.component';
import { CompanyInfoComponent } from './pages/company-info/company-info.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CategoriesComponent } from './pages/categories/categories.component';




const routes: Routes = [
  {
    path: 'register', component: RegesterationComponent, children:[
    { path: "", redirectTo: "account-type", pathMatch: "full" },
    {path: 'account-type', component: AccountTypeComponent, title: "تسجيل جديد | منصة شغل"},
    { path: 'freelancer-type', component: FreelancerTypeComponent, title: "تسجيل مشتغل جديد | منصة شغل" },
    { path: 'account-info', component: AccountInfoComponent, title: "تسجيل مستخدم جديد | منصة شغل" },
    { path: 'account-otp', component: OtpVerificationComponent },
    { path: 'location-info', component: LocationMapComponent },
    { path: 'freelancer-info', component: FreelancerInfoComponent },
    { path: 'company-info', component: CompanyInfoComponent },
    { path: 'welcome', component: WelcomeComponent },
    { path: 'categories', component: CategoriesComponent },
  ] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class regesterationRoutingModule { }
