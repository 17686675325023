import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesComponent } from './features.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterLinkActive, RouterModule } from '@angular/router';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { NgOtpInputModule } from 'ng-otp-input';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { RegesterationModule } from './components/regesteration/regesteration.module';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { CountUpDirective } from '../core/directives/count-up.directive';
import { AnimateDirective } from '../core/directives/animate.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatPageComponent } from './components/chat-page/chat-page.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChatDatePipe } from '../core/pipes/chat-date.pipe';
import { environment } from 'src/environments/environment';

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    FeaturesComponent,
    HomePageComponent,
    CountUpDirective,
    AnimateDirective,
    ChatPageComponent
  ],
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatPseudoCheckboxModule,
    MatTreeModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    IntlInputPhoneModule,
    FormsModule,
    RouterModule,
    BrowserModule,
    NgxIntlTelInputModule,
    RouterLinkActive,
    BrowserAnimationsModule,
    NgOtpInputModule,
    MatSelectModule,
    MatInputModule,
    NgSelectModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatListModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RegesterationModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,
      language: 'ar',
      libraries: ['places', 'geometry']
    }),
    GoogleMapsModule,

  ],
  exports:[
    // HomePageComponent,
    // AnimateDirective
  ],
  providers:[ ChatDatePipe]
  // providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },]
})
export class FeaturesModule { }
