import { HttpClient } from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare const google: any;
@Injectable({
  providedIn: 'root'
})
export class MapsService {

  constructor(private http: HttpClient, private mapsAPILoader: MapsAPILoader,) { }

  getRouteName(latitude: number, longitude: number): Promise<any>
  {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=ar&key=${environment.googleMapKey}`;
    return this.http.get<any>(url).toPromise().then
      (response => {
        console.log(response.results);
        const data = {
          address: response.results.formatted_address,
        }
        if (response.results && response.results.length > 0)
        { return response.results.find(el => el.types.includes('sublocality_level_1')) } throw new Error('Could not retrieve route name.');
        // { return response.results[0].formatted_address; } throw new Error('Could not retrieve route name.');
      });
  }

  mapAddress(route){
    console.log(route)
    return route.address_components.reduce((acc, el) => {
      if (el.types.includes('country')) {
          acc.country = el.long_name;
      }
      if (el.types.includes('administrative_area_level_1')) {
          acc.city = el.long_name;
      }
      if (el.types.includes('administrative_area_level_2')) {
          acc.area = el.long_name;
      }
      return acc;
  }, {});
  }
}

