  <!-- tree -->
<mat-tree [dataSource]="this._tree.dataSource"
  [treeControl]="this._tree.treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node class="d-flex justify-content-between"
    *matTreeNodeDef="let node" matTreeNodePadding>
    <!-- use a disabled button to provide padding for tree leaf -->
    <div>
      <input mat-icon-button name="category" id="{{node.id}}"
        class="font-Regular-s16 mx-2" type="checkbox" 
        [checked]="this._tree.checklistSelection.isSelected(node) || node.checked"
        (change)="this._tree.todoLeafItemSelectionToggle(node)">
      <label for="{{node.id}}">{{node.name}}</label>
    </div>
    <button type="button" mat-icon-button id="Toggle" aria-label="Toggle"></button>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node class="d-flex justify-content-between"
    *matTreeNodeDef="let node;when :this._tree.hasChild" matTreeNodePadding>
    <div>
      <input mat-icon-button name="category" id="{{node.id}}"
        class="font-Regular-s16 mx-2" type="checkbox" 
        [checked]="this._tree.descendantsAllSelected(node) || node.checked"
        [indeterminate]="this._tree.descendantsPartiallySelected(node)"
        (change)="this._tree.todoItemSelectionToggle(node)">
      <label for="{{node.id}}">{{node.name}}</label>
    </div>
    <button type="button" mat-icon-button matTreeNodeToggle id="Toggle"
      [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon
        class="mat-icon-rtl-mirror  {{this._tree.treeControl.isExpanded(node) ? 'text-dark-color' : ''}}">
        {{this._tree.treeControl.isExpanded(node) ? 'expand_more' :
        'chevron_right'}}
      </mat-icon>
    </button>
  </mat-tree-node>
</mat-tree>
