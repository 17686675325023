<div class="cards">  
    <div class="card is-loading border-f-radius-s15 mb-3"
    *ngFor="let item of skeleton">
      <div class="content p-4 relative ">
        <div class="d-flex w-100 gap-3">
            <img *ngIf="open" class="w-32">
            <div class="w-100">
                <i class="absolute top-6 left-6 w-12"></i>
                <h2 class="w-25"></h2>
                <h2 class="w-50 mt-3"></h2>
                <div class="d-flex mt-3 gap-3">
                    <p class="w-25"></p>
                    <p class="w-25"></p>
                    <p class="w-25"></p>
                </div>
            </div>
        </div>
        <div class="d-flex mt-4 justify-between {{Margen}}">
        <div class="d-flex gap-5">
            <p class="w-10"></p>
            <p class="w-10"></p>
            <p class="w-10"></p>
        </div>
        <h2 *ngIf="!open" class="w-25"></h2>
        <h3 *ngIf="open" class="w-25"></h3>
        </div>
      </div>
    </div>
  </div>