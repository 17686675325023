import { Component, HostListener} from '@angular/core';
import {  Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { RequestService } from 'src/app/core/services/request.service';

declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  open:boolean = true
  notifications:any
  private prevScrollPos = window.pageYOffset;
  private scrollDeltaThreshold = 100;
  public isNavbarVisible = true;
  userData:any
  available: boolean = true
  calcNotification:number = 0;
  reqNotifications:any
  constructor(private profile: ProfileService ,public _BreakPointService: BreakPointService, public activeRouter: Router, public _AuthService: AuthService, public _Req: RequestService,public _chat:ChatService) {
    this._chat.currentMessage.subscribe((res: any) => {
      this.handleNotification(res)
    })
    this.getNotification()
    }
    getNotification(){
      this.profile.getFiveNotification().subscribe((res:any)=>{
        console.log(res)
      this.reqNotifications = res.data.data
      this.calcNotification = this.reqNotifications.map(el => el.read == null).filter(Boolean).length
  })
}
  ngOnInit(): void {
    $('.navbar-collapse a').click(function () {
      $(".navbar-collapse").collapse('hide');
    });
    // if (this._AuthService.userValue?.status) {
    //   this.profile.getUserData(this._AuthService.id).subscribe((res: any) => {
    //     this.userData = res.data.data
    //     if (res.data.data.available == 1) {
    //       this.available = false
    //     } else {
    //       this.available = true
    //     }
    //   })
    // }
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };

  }
  public get counter() {
    return this._chat.counter.getValue();
}

  handleNotification(notification: any) {
    // Do something with the notification
    // Call your desired function here based on the notification content
    // For example: this.myFunction(notification);
    this.notifications = notification?.notification;
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollPos = window.pageYOffset;
    const scrollDelta = Math.abs(currentScrollPos - this.prevScrollPos);
    if (scrollDelta > this.scrollDeltaThreshold) {
      if (currentScrollPos > this.prevScrollPos) {
        this.isNavbarVisible = false;
      } else {
        this.isNavbarVisible = true;
      }
    }
    this.prevScrollPos = currentScrollPos;
  }
  notification(){
    setTimeout(() => {
      this.open = false
    }, 1);
  }
  closeNotification(){
    this.open = true
  }
  @HostListener('document:click', ['$event'])
  handleClick() {
    if (this.open == false) {
      this.open = true
    }
  }
}
