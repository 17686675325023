import { Component, EventEmitter, Output } from '@angular/core';
import { TreeService } from 'src/app/core/services/tree.service';


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent {
  @Output() check = new EventEmitter<any>();
  constructor(public _tree:TreeService) {
    this._tree.category.subscribe(res => {
      this.check.emit(res)
    })
  }
}
