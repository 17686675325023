import { Component } from '@angular/core';

@Component({
  selector: 'app-freelancer-offer',
  templateUrl: './freelancer-offer.component.html',
  styleUrls: ['./freelancer-offer.component.scss']
})
export class FreelancerOfferComponent {

}
