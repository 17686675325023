import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  public displayOffer:boolean = false;
  public displayStatus:boolean = false;
  public displayOfferPopup:boolean = true;
  public RequestOwner:boolean = true;
  public allOffer:boolean = true;
  public requestEdit:boolean = true;

  constructor(private _http: HttpClient) { }
  addOffer(data:any){
    return this._http.post(`${environment.apiUrl}/api/offer/store` , data)
  }
  editOffer(data: any, offerId:any){
    return this._http.post(`${environment.apiUrl}/api/offer/update/${offerId}`, data)
  }
  acceptOffer(id:any){
    return this._http.post(`${environment.apiUrl}/api/offer/approve/${id}`, '')
  }
  uploadFilesOffer(id:any ,data:any){
    return this._http.post(`${environment.apiUrl}/api/task/done_freelancer/${id}`, data)
  }
  rating(data:any){
    return this._http.post(`${environment.apiUrl}/api/setting/review`, data)
  }
  getAllOffer(id:any){
    return this._http.get(`${environment.apiUrl}/api/offer/list?task_id=${id}`)
  }
  acceptFileOffer(id: any) {
    return this._http.post(`${environment.apiUrl}/api/task/done_client/${id}`, '')
  }
  rejectFileOffer(id: any) {
    return this._http.post(`${environment.apiUrl}/api/task/unapprove_client`, {id:id})
  }
}
