<!-- regesteration nav -->
<div class="container-fluid px-0 d-flex flex-column justify-content-between bg-white-sup position-relative vh-100 overflow-y-scroll">
  <nav class="container px-0 pt-3 pb-4 pe-4">
    <!-- navbar logo -->
    <a href="javascript:void(0)" class="" aria-label="logo">
      <img class="logo-holder cursor-click" src="../../../../assets/Icons/main-logo.svg"loading="lazy"
      alt="Your Company logo"  routerLink="/">
    </a>
  </nav>
  <div class="container bg-white-sup lg:h-full px-md-0 mb-md-4 px-3">
    <div class="container h-100 mb-4">
      <router-outlet></router-outlet>
    </div>
  </div>
    <footer class="bg-dark mt-md-auto pt-3 pb-3 d-flex justify-content-center" style="z-index: 1;"  [ngClass]="{'position-fixed bottom-0 start-0 end-0': _BreakPointService.isMobile == false}">
      <p class="m-0 font-Regular-s14 text-white-color">
        Copyright ©2023 All rights reserved | Shogol Platform by lun startup studio
      </p>
    </footer>
</div>
