import { Injectable } from '@angular/core';
import { addDoc,collection,collectionData,doc,Firestore,getDoc,onSnapshot,orderBy,query,updateDoc,where,} from '@angular/fire/firestore';
import { getMessaging } from "firebase/messaging";
import {  BehaviorSubject,concatMap, map, Observable, take } from 'rxjs';
import { Chat, Message } from 'src/app/core/models/chats';
import { UsersService } from './users.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { FormControl } from '@angular/forms';
import { UploadImageFirebaseService } from 'src/app/core/services/upload-image-firebase.service';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  currentUser:any;
  currentUserID:any
  messaging = getMessaging();
  currentMessage = new BehaviorSubject(null);
  chatListControl = new FormControl(['']);
  searchControl = new FormControl('')
  message: any;
  token: any;
  counter = new BehaviorSubject<number>(0);
  constructor(
    private firestore: Firestore,
    private usersService: UsersService,
    private _angularFireMessaging:AngularFireMessaging,
    private _http: HttpClient,
    private uploadImageFirebaseService:UploadImageFirebaseService
  ) {
    this.usersService.currentUserProfile$.subscribe((user)=>{
      this.currentUser=user
    });
  }

  requestPermission(){
    this._angularFireMessaging.requestToken.subscribe((token:any)=> {
      this.token = token
    },(err)=>{
    })
  }

  receiveMessage(){
    this._angularFireMessaging.messages.subscribe((payload:any)=> {
      this.currentMessage.next(payload)
    })
  }

  get myChats$(): Observable<Chat[]> {
    const ref = collection(this.firestore, 'chats');
    
    return this.usersService.currentUserProfile$.pipe(
      concatMap((user) => {
        const myQuery = query(
          ref,where('userIds', 'array-contains', user?.uid),
          //indexing
          orderBy('lastMessageDate', 'desc')
          );          
        collectionData(myQuery,{ idField: 'id' }).subscribe((data)=>{this.counter.next(data.filter((chat)=>chat["haveUnseenMessages"]==true && this.checkIfUserIsSender(this.currentUser?.uid,chat["lastMsgsenderId"]) ).length)})
        return collectionData(myQuery, { idField: 'id' }).pipe(
          map((chats: any) => this.addChatNameAndPic(user?.uid, chats))
        ) as Observable<Chat[]>;
      })
    );
  }

  checkIfUserIsSender(user:any,lastMsgsenderId:any){
    if(user !== lastMsgsenderId) return true ;
    else return false;
  }

  createChat(otherUser:any): Observable<string> {
    const ref = collection(this.firestore, 'chats');
    return this.usersService.currentUserProfile$.pipe(
      take(1),
      concatMap((user) =>
        addDoc(ref, {
          lastMessageDate:null,
          userIds: [user?.uid, otherUser?.uid],
          users: [
            {
              displayName: user?.displayName ?? '',
              photoURL: user?.photoURL ?? '',
            },
            {
              displayName: otherUser.displayName ?? '',
              photoURL: otherUser.photoURL ?? '',
            },
          ],
          unseenMessages:0
        })
      ),
      map((ref) => ref.id )
    );
  }

  isExistingChat(otherUserId: string): Observable<string | null> {
    return this.myChats$.pipe(
      take(1),
      map((chats) => {
        for (let i = 0; i < chats.length; i++) {
          if (chats[i].userIds.includes(otherUserId)) {
            return chats[i].id;
          }
        }
        return null;
      })
    );
  }

  addChatMessage(chatId: string, message: string): Observable<any> {
    const ref = collection(this.firestore, 'chats', chatId, 'messages');
    const chatRef = doc(this.firestore, 'chats', chatId);
    const today = new Date();
    return this.usersService.currentUserProfile$.pipe(
      take(1),
      concatMap((user) =>{
        this.currentUserID=user?.uid
        return addDoc(ref, {
          text: message,
          senderId: user?.uid,
          sentDate: today,
          seen:false,
          mid:Date.now().toString()
        })
      }
      ),
      concatMap((msg) =>{
        let docRef=doc(this.firestore,'chats', chatId, 'messages',msg.id);
        return  updateDoc(docRef, {
          text: message,
          senderId: this.currentUserID,
          sentDate: today,
          seen:false,
          mid:msg.id
        })
      }
      ),
      concatMap(() =>
        updateDoc(chatRef, { lastMessage: message, lastMessageDate: today , haveUnseenMessages:true, lastMsgsenderId:this.currentUserID})
      )
    )
  }

  getChatMessages$(chatId: string): Observable<Message[]> {
    const ref = collection(this.firestore, 'chats', chatId, 'messages');
    const chatRef = doc(this.firestore, 'chats', chatId);
    const queryAll = query(ref, orderBy('sentDate', 'asc'));
    collectionData(queryAll).subscribe((chatMessages)=>{
      if(chatMessages?.length>0 && chatMessages[chatMessages?.length-1]['senderId']!=this.currentUser.uid)
        updateDoc(chatRef, { haveUnseenMessages:false}) 
      this.getUnseenMessages(chatMessages,chatId)
    });
    return collectionData(queryAll) as Observable<Message[]>;
  }

  getUnseenMessages(chatMessages:any,chatId:any){
    this.usersService.currentUserProfile$.subscribe((user)=>{
      let recevedMessages=chatMessages.filter((msg:any)=>user?.uid !== msg.senderId && msg.seen ===false )
      if(recevedMessages.length>0)
        this.updateMessageStatus(recevedMessages,chatId)
    })
  }

  updateMessageStatus(messages:any[],chatId: string){
    for(let i=0;i<messages.length;i++){
      let docRef=doc(this.firestore,'chats', chatId, 'messages',messages[i].mid);
      onSnapshot(docRef,(message)=>updateDoc(docRef,{
        ...message.data,seen:true
      }))
    }
  }

  addChatNameAndPic(currentUserId: string | undefined, chats: Chat[]): Chat[] {
    chats.forEach((chat: Chat) => {
      const otherUserIndex = chat.userIds.indexOf(currentUserId ?? '') === 0 ? 1 : 0;
      const { displayName, photoURL } = chat.users[otherUserIndex];
      chat.chatName = displayName;
      chat.chatPic = photoURL;
      if(!photoURL || typeof(photoURL)=='object'){
        const docRef=doc(this.firestore,'users',chat.userIds[otherUserIndex]);
        onSnapshot(docRef,(doc)=>{
          if(doc.data()['photoURL']) chat.chatPic =doc.data()['photoURL'];
          else chat.chatPic = null;

        })
      }

    });
    
    return chats;
  }

  uploadFireStoreImage(event: any, user: any) {
    user.subscribe((u)=>{
      this.uploadImageFirebaseService.uploadImage(event.target.files[0], `images/profile/${u.uid}`);
      this.changeChatePhoto(u)
    })
  }

  changeChatePhoto(data:any){
    const storage = getStorage();
    const starsRef = ref(storage, `images/profile/${data.uid}`);
    getDownloadURL(starsRef).then((url) => {
      let docRef=doc(this.firestore,'users',data.uid);
        updateDoc(docRef, {
          ...getDoc(docRef),photoURL:url
        })}
      )
  }

  getUserDataFromChat(username: any) {
    return this._http.get(`${environment.apiUrl}/api/user/profile?username=${username}`)
  }
}
